import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spinner } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import api from 'src/lib/api'
import Mixpanel from 'src/lib/mixpanel'
import { useNavigate } from 'react-router-dom'

export default function RenameDocumentModal({
  isEditOpen,
  onEditOpenChange,
  documentToEdit,
  updateDocuments,
}: {
  isEditOpen: boolean
  onEditOpenChange: (isOpen?: boolean) => void
  documentToEdit: any
  updateDocuments: () => void
}) {
  const [loading, setLoading] = useState<boolean>(false)
  const [documentName, setDocumentName] = useState<string>(documentToEdit?.name ?? '')
  const navigate = useNavigate()
  useEffect(() => {
    if (documentToEdit) {
      setDocumentName(documentToEdit?.title ?? '')
    }
  }, [documentToEdit])
  const renameDocument = async () => {
    if (documentName.length === 0) return
    setLoading(true)
    let res
    try {
      res = await api.post('/urs/' + documentToEdit.uuid, {
        title: documentName,
      })
      Mixpanel.track('Document Edit', {
        document_name: documentName,
      })
    } catch (error) {
      console.error('Error creating workspace:', error)
    } finally {
      await updateDocuments?.()
      setLoading(false)
      setDocumentName('')
      onEditOpenChange()
    }
  }
  return (
    <Modal isOpen={isEditOpen} onOpenChange={onEditOpenChange}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1"></ModalHeader>
            <ModalBody>
              <div className="flex flex-col grow gap-7">
                <div className="flex flex-col items-center">
                  <p className="text-2xl font-semibold">Rename Document</p>
                  <p className="text-base text-center">Update document title.</p>
                </div>
                <div className="flex flex-col gap-3 items-center">
                  <Input
                    isRequired
                    type="text"
                    label="Project name"
                    placeholder="e.g. GreenLeaf Design"
                    className="max-w-xs"
                    value={documentName}
                    onChange={(e) => setDocumentName(e.target.value)}
                    autoFocus={true}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        renameDocument()
                      }
                    }}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Cancel
              </Button>
              <Button color="secondary" onPress={renameDocument} isDisabled={documentName.length === 0 || loading}>
                {loading ? 'Renaming ' : 'Rename '}
                {loading && <Spinner size={'sm'} />}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
