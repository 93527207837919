import React, { useEffect, useCallback, useState, forwardRef, useImperativeHandle, useRef, Ref } from 'react'
import ReactDOM from 'react-dom/client'
import debounce from 'lodash.debounce'
import DragHandle from '@tiptap-pro/extension-drag-handle-react'
import { useEditor, EditorContent } from '@tiptap/react'
import { Decoration, DecorationSet } from '@tiptap/pm/view'
import { useSocketContextData } from 'src/context/socket'
import {
  Spinner,
  Tooltip,
  ScrollShadow,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
} from '@nextui-org/react'
import { SlashCmd, SlashCmdProvider } from '@harshtalks/slash-tiptap'
import Mixpanel from 'src/lib/mixpanel'

import { Icon } from '@iconify/react'

import './editor-styles.css'
import SmartTextMenu from 'src/pages/ursPage/extensions/SmartTextMenu'
import { createSuggestions } from 'src/pages/ursPage/extensions/SlashPlugin'
import { createExtensions } from 'src/pages/ursPage/extensions/extensions'

export const TipTapEditor = forwardRef(
  (
    {
      markdown,
      ursUUID,
      isReadOnly,
    }: {
      markdown: string
      ursUUID?: string
      isReadOnly?: boolean
    },
    ref,
  ) => {
    const [tableContent, setTableContent] = useState<any[]>([])
    const [currentHead, setCurrentHead] = useState<number>(0)
    const [loadingError, setLoadingError] = useState<boolean>(false)
    const [loadingImage, setLoadingImage] = useState<boolean>(false)
    const { isOpen: isMediaModalOpen, onOpen: onOpenMediaModal, onOpenChange: onOpenChangeMediaModal } = useDisclosure()
    const [imageToEdit, setImageToEdit] = useState<any>(null)
    const suggestions = createSuggestions({ onOpenMediaModal })
    const extensions = createExtensions({ setTableContent, suggestions })

    const savingBlockRef = useRef<HTMLDivElement | null>(null)
    const savedBlockRef = useRef<HTMLDivElement | null>(null)
    const saveToastBlockRef = useRef<HTMLDivElement | null>(null)
    const debouncedUpdate = useCallback(
      debounce(async (editor) => {
        if (ursUUID && !isReadOnly) {
          // Show "Saving changes" and hide "Changes saved"
          savingBlockRef.current?.classList.remove('hidden')
          savedBlockRef.current?.classList.add('hidden')
          saveToastBlockRef.current?.classList.add('loading')

          const content = editor.storage.markdown.getMarkdown()
          await io.emitWithAck('updateDocument', {
            content,
            ursUUID,
            token: localStorage.getItem('accessToken'),
          })

          // Hide "Saving changes" and show "Changes saved"
          savingBlockRef.current?.classList.add('hidden')
          savedBlockRef.current?.classList.remove('hidden')

          setTimeout(() => {
            // Hide "Changes saved" after 1.5 seconds
            savedBlockRef.current?.classList.add('hidden')
            saveToastBlockRef.current?.classList.remove('loading')
          }, 1500)
        }
      }, 500), // Delay of 500ms
      [],
    )

    const helperTextMap = {
      'Executive summary':
        'Provide a concise overview of the project, outlining goals, challenges, and the value offered.',
      'About us / company information':
        'Detail your agency’s background, expertise, and relevant experience for the client’s needs.',
      'Client challenges / problem statement':
        'Clearly define the client’s challenges, pain points, or needs to address in the proposal.',
      'Project goals & scope':
        'List specific objectives, deliverables, and an outline of the proposed work to be completed.',
      'Solution overview / approach':
        'High-level description of your methodology, tools, or processes to meet client needs.',
      'Design & development strategy':
        'Outline project phases, including design, development, and implementation steps.',
      'Project timeline / milestones':
        'Estimated timeline and key milestones for the project, with clarity on deliverables.',
      'Budget / pricing':
        'Provide a cost breakdown, total cost, and justification. Use tables for clarity where possible.',
      'Payment terms': 'Detail the payment structure, terms, and conditions like milestone-based payments or deposits.',
      'Conclusion / next steps':
        'Summarize the value your agency brings and outline the next steps for approval or collaboration.',
      'Thank you & contact information':
        'Include a thank-you message to the client, along with your agency’s contact details for follow-up.',
    }

    const editor = useEditor({
      extensions,
      editable: !isReadOnly,
      content: markdown,
      onUpdate: ({ editor }) => {
        debouncedUpdate(editor)
      },
      editorProps: {
        decorations: (state) => {
          const { doc } = state
          const decorations: Decoration[] = []

          doc.descendants((node, pos) => {
            if (node.type.name === 'heading' && node.attrs.level === 2) {
              const headingText = node.textContent.trim()
              const helperText = helperTextMap[headingText] || 'Provide additional details for this section'

              decorations.push(
                Decoration.widget(pos, () => {
                  const container = document.createElement('div')
                  container.style.position = 'relative' // Ensure it's positioned relative to the heading

                  // Create the helper text element
                  const helperDiv = document.createElement('div')
                  helperDiv.className = 'absolute-helper-text'

                  // Render the helper text with an icon
                  const icon = <Icon icon="solar:lightbulb-bolt-outline" width="30" color="#13746e" />
                  const helperTextContainer = (
                    <div className="flex w-fit max-w-full line-clamp-1 z-10 items-center gap-2 bg-[#C3E3E8] p-2 rounded-lg">
                      {icon}
                      <span className="text-sm text-black">{helperText}</span>
                    </div>
                  )
                  const root = ReactDOM.createRoot(helperDiv)
                  root.render(helperTextContainer)

                  // Append the helper text above the heading
                  container.appendChild(helperDiv)
                  return container
                }),
              )
            }
          })

          return DecorationSet.create(doc, decorations)
        },
        attributes: {
          class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none',
        },
      },
    })
    const insertDiffInEditor = (highlightedMarkdown: string) => {
      if (!editor) return
      // Clear the editor content to prevent appending to previous content
      editor.commands.clearContent()

      const diffPattern = /(@@COVER@@([\s\S]*?)@@END_COVER@@)/g
      let match
      let lastIndex = 0
      while ((match = diffPattern.exec(highlightedMarkdown)) !== null) {
        // Insert unchanged text before the match
        const unchangedText = highlightedMarkdown.slice(lastIndex, match.index)
        if (unchangedText) {
          try {
            editor.commands.insertContent(unchangedText)
          } catch (error) {
            console.log('uncahnged content: ', unchangedText)
          }
        }

        // Remove the diff markers and extract the text
        const coverPageText = match[0].replace(/@@COVER@@/g, '').replace(/@@END_COVER@@/g, '')
        // Insert the parsed content as a diff node
        editor.commands.insertContent({
          type: 'cover',
          attrs: {
            content: coverPageText,
          },
          content: [
            {
              type: 'text',
              text: coverPageText,
            },
          ],
        })
        lastIndex = match.index + match[0].length
      }

      // Insert any remaining unchanged text after the last match
      if (lastIndex < highlightedMarkdown.length) {
        editor.commands.insertContent(highlightedMarkdown.slice(lastIndex))
      }
    }

    useEffect(() => {
      if (editor) {
        editor.commands.setContent('')
        insertDiffInEditor(markdown)
      }
    }, [markdown, editor])

    useEffect(() => {
      return () => {
        if (editor) {
          editor.destroy() // Clean up the editor on unmount
        }
        // Cancel the debounce on unmount to prevent memory leaks
        debouncedUpdate.cancel()
      }
    }, [editor, debouncedUpdate])

    const { io } = useSocketContextData()

    useImperativeHandle(
      ref,
      () => {
        return {
          getMarkdown: () => {
            return editor?.storage.markdown.getMarkdown()
          },
          setMarkdown: (content: string) => {
            editor?.commands?.setContent(content)
          },
        }
      },
      [editor],
    )
    useEffect(() => {
      if (onOpenMediaModal) {
        //@ts-ignore
        window.documentEditImage = (imageRef: Ref<HTMLElement | null>) => {
          setImageToEdit(imageRef)
          onOpenMediaModal()
        }
      }
    }, [onOpenMediaModal])
    if (!editor) {
      return null
    }
    const scrollToItem = (id: string) => {
      const headingElement = document.getElementById(id)
      console.log(headingElement, id)
      if (headingElement) {
        headingElement.scrollIntoView({ behavior: 'smooth' })

        // Extract text from <strong> element if it exists
        const strongElement = headingElement.querySelector('strong')
        const headingText = strongElement ? strongElement.textContent : 'Unknown'

        // Track the scroll event for Table of Content navigation
        Mixpanel.track('Table of content item clicked', {
          heading_text: headingText,
          element_id: id,
        })

        headingElement.classList.add('highlight')
        setTimeout(() => {
          headingElement.classList.remove('highlight') // Remove highlight after 2 seconds
        }, 2000)
      }
    }

    // FUNCTION: Smart Text Menu Auto-Opener
    // Automatically opens the 'Smart Text Menu' on the document
    // to intuitively highlight additional features for users.
    // This functionality is triggered only once per registered user
    // and exclusively for their first document.
    // *************************************************************
    useEffect(() => {
      if (editor) {
        // Show the Bubble Menu after 2 seconds by creating a selection
        const timer = setTimeout(() => {
          const { state, view } = editor
          const { doc } = state
          try {
            const userString = localStorage.getItem('user')
            const user = JSON.parse(userString)
            if (!user.isNew) {
              return
            }
            user.isNew = false
            localStorage.setItem('user', JSON.stringify(user))
          } catch (err) {
            console.log('Error: ', err)
          }
          // Ensure the positions are valid by checking the document size
          const startPos = 0
          const endPos = Math.min(10, doc.content.size) // Limit end position to document size

          if (startPos < doc.content.size) {
            view.dispatch(state.tr.setSelection(state.selection.constructor.create(state.doc, startPos, endPos)))

            // Optionally focus the editor if needed
            view.focus()

            // Wait for the menu to become visible and then click the desired element
            setTimeout(() => {
              const menuItem = document.getElementById('smart-text-menu-ai-item')
              if (menuItem) {
                menuItem.click()
              }
            }, 500) // Adjust delay as needed to ensure menu visibility
          }
        }, 500)

        return () => clearTimeout(timer) // Clear timer on component unmount
      }
    }, [editor])
    const imageUrlInput = useRef(null)
    const activeNodePos = useRef<number>(-1)
    const createNewBlock = () => {
      if (!editor) return
      const { state, view } = editor
      const transaction = state.tr.insert(activeNodePos.current, state.schema.nodes.paragraph.create())
      //.insertText('/', activeNodePos.current + 1)
      view.dispatch(transaction)
      editor
        .chain()
        .focus()
        .setTextSelection(activeNodePos.current + 1)
        .run()
    }
    const onNodeChange = useCallback((props) => {
      const { node, pos } = props
      if (node) {
        activeNodePos.current = pos + node.nodeSize
      } else {
        activeNodePos.current = -1
      }
    }, [])
    return (
      <SlashCmdProvider>
        <div className={'crepe relative w-full max-w-full'}>
          <div className={'toc-wrapper'}>
            <div className={'fixed'}>
              <div className={'toc'}>
                <div className={'toc-mini p-2'}>
                  {tableContent
                    .filter((item) => item.level < 3)
                    .map((item) => (
                      <div key={item.label} className="toc-item-mini toc-light">
                        ▃▃
                      </div>
                    ))}
                </div>
                <div className="toc-list flex flex-col shadow-md rounded-xl h-[300px] p-4 text-default overflow-y-scroll bg-white w-[250px] scrollbar-hide opacity-0 transition-opacity duration-300 hover:opacity-100 hover:block hidden">
                  <ScrollShadow className="grow mb-3" hideScrollBar size={40}>
                    {tableContent.map((item, index) => (
                      <div
                        onClick={() => scrollToItem(item.id)}
                        title={item.textContent}
                        className="toc-item cursor-pointer text-default-700 text-xs p-2 rounded-lg hover:bg-gray-100 truncate"
                      >
                        {item.textContent}
                      </div>
                    ))}
                  </ScrollShadow>
                </div>
              </div>
            </div>
          </div>
          <div
            ref={saveToastBlockRef}
            className={`fixed bottom-8 left-1/2 transform -translate-x-1/2 pb-2 bg-black/70 backdrop-blur-sm pl-5 pr-5 pt-2 rounded-xl notification transition-opacity duration-100 z-40`}
          >
            <div ref={savingBlockRef} className="flex gap-2 items-center hidden">
              <Spinner size="sm" color="white" />
              <p>Saving changes</p>
            </div>
            <div ref={savedBlockRef} className="flex gap-2 items-center hidden">
              <Icon icon="lucide:check" />
              <p className="thumbs-up">Changes saved</p>
            </div>
          </div>
          <div className={'tiptap'}>
            {editor && (
              <SlashCmd.Root editor={editor}>
                <SlashCmd.Cmd
                  className={
                    'rounded-md border border-1 border-default-300 bg-white flex flex-row p-4 pt-2 pb-2 shadow card w-max'
                  }
                >
                  <SlashCmd.Empty>No options available</SlashCmd.Empty>
                  <SlashCmd.List>
                    {(suggestions ?? []).map((item) => {
                      return (
                        <SlashCmd.Item
                          value={item.title}
                          onCommand={(val) => {
                            item.command(val)
                          }}
                          key={item.title}
                          className={'py-1 z-50'}
                        >
                          <span className="flex flex-row w-full font-normal items-center gap-2 cursor-pointer">
                            <Icon icon={item.icon} className="text-secondary" width={20} />
                            {item.title}
                          </span>
                        </SlashCmd.Item>
                      )
                    })}
                  </SlashCmd.List>
                </SlashCmd.Cmd>
              </SlashCmd.Root>
            )}

            <EditorContent editor={editor} content={markdown}>
              {editor && <SmartTextMenu editor={editor} ursUUID={ursUUID} />}
            </EditorContent>
            {editor && !isReadOnly && (
              <DragHandle
                editor={editor}
                tippyOptions={{
                  placement: 'left-start',
                  interactive: true,
                  delay: [0, 5000], // Add more time before hiding
                  hideOnClick: false,
                  appendTo: 'parent', // Ensures tooltip is appended to the relevant container
                  onMount(instance) {
                    // Prevent tooltip from hiding accidentally
                    instance.popper.style.pointerEvents = 'auto'
                  },
                  onHide(instance) {
                    // Cleanup to allow proper hiding
                    instance.popper.style.pointerEvents = ''
                  },
                  popperOptions: {
                    modifiers: [
                      {
                        name: 'preventOverflow',
                        options: {
                          boundary: 'viewport', // Ensures tooltip stays visible
                        },
                      },
                    ],
                  },
                }}
                onNodeChange={onNodeChange}
                className="w-[50px] absolute -left-[40px]"
              >
                <div className={'flex p-0 items-top z-50'}>
                  <Tooltip content="Add block below" placement="bottom" offset={5} size="sm" radius="sm">
                    <div className="flex w-[25px] cursor-pointer text-default-300" onClick={() => createNewBlock()}>
                      <Icon icon="tabler:plus" width="20" />
                    </div>
                  </Tooltip>
                  <Tooltip content="Drag to move" placement="bottom" offset={5} size="sm" radius="sm">
                    <div className="flex w-[25px] cursor-grab text-default-300">
                      <Icon icon="tabler:grip-vertical" width="20" />
                    </div>
                  </Tooltip>
                </div>
              </DragHandle>
            )}
            <Modal isOpen={isMediaModalOpen} onOpenChange={onOpenChangeMediaModal} size={'4xl'}>
              <ModalContent>
                {(onClose) => (
                  <>
                    <ModalHeader className="flex flex-col gap-1">Add Image</ModalHeader>
                    <ModalBody>
                      <div className={'flex flex-col gap-4 '}>
                        <div className={'flex flex-row justify-between gap-10'}>
                          <Input
                            autoFocus
                            ref={imageUrlInput}
                            variant="faded"
                            labelPlacement="inside"
                            label={'Image link'}
                            placeholder="Enter your media link: e.g https://powerdocs.so/media/content.png"
                            className="w-full"
                            errorMessage={'Invalid image url'}
                            onChange={() => setLoadingError(false)}
                            isInvalid={loadingError}
                          />
                        </div>
                        <span className={'text-xs italic text-default-400'}>
                          Tip: You can make your proposal standsout by inserting rich content to visualize ideas to your
                          clients.
                        </span>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="secondary"
                        onPress={() => {
                          if (imageUrlInput.current) {
                            setLoadingError(false)
                            setLoadingImage(true)
                            const imageUrl = imageUrlInput.current.value

                            if (!imageUrl) {
                              console.error('Image URL is empty.')
                              setLoadingImage(false)
                              return
                            }

                            // Validate the image URL
                            const img = new Image()
                            img.onload = () => {
                              if (imageToEdit?.current) {
                                imageToEdit.current.src = imageUrl
                                imageToEdit.current.alt = imageUrl
                                setImageToEdit(null)
                              } else {
                                // If valid, execute the editor commands
                                editor?.commands.setMedia({
                                  src: imageUrl,
                                  'media-type': 'img',
                                  alt: 'Something else',
                                  title: 'Something',
                                  width: '800',
                                  height: '400',
                                })
                                imageUrlInput.current.value = ''
                              }
                              setLoadingImage(false)
                              onClose()
                            }

                            img.onerror = () => {
                              // If invalid, show an error
                              console.error('Invalid image URL.')
                              setLoadingError(true)
                              setLoadingImage(false)
                            }

                            img.src = imageUrl
                          }
                        }}
                      >
                        {loadingImage ? (
                          <>
                            {'Validating image'} (<Spinner size={'sm'} />
                          </>
                        ) : (
                          'Insert Image'
                        )}
                      </Button>
                    </ModalFooter>
                  </>
                )}
              </ModalContent>
            </Modal>
          </div>
        </div>
      </SlashCmdProvider>
    )
  },
)
