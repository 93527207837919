import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spinner } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from 'src/lib/api'

export default function DuplicateDocumentModal({
  openedDuplicateDocument,
  onOpenDuplicateDocumentChange,
  documentToEdit,
  updateDocuments,
}: {
  openedDuplicateDocument: boolean
  onOpenDuplicateDocumentChange: (isOpen: boolean) => void
  documentToEdit: any
  updateDocuments: () => Promise<void>
}) {
  const [duplicatingDocument, setDuplicatingDocument] = useState<boolean>(false)
  const [documentTitle, setDocumentTitle] = useState('')
  const navigate = useNavigate()
  const duplicateDocument = async () => {
    setDuplicatingDocument(true)
    // TODO: Duplicate document here
    const res = await api.post('/urs/' + documentToEdit.uuid + '/duplicate', {
      title: documentTitle,
    })
    if (res.data?.uuid) {
      await updateDocuments()
    }
    onOpenDuplicateDocumentChange(false)
    setDuplicatingDocument(false)
  }
  useEffect(() => {
    if (documentToEdit) {
      setDocumentTitle(documentToEdit.title ?? '')
    }
  }, [documentToEdit])
  return (
    <Modal size={'3xl'} isOpen={openedDuplicateDocument} onOpenChange={onOpenDuplicateDocumentChange}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1"></ModalHeader>
            <ModalBody>
              <div className="flex flex-col grow gap-7">
                <div className="flex flex-col items-center">
                  <p className="text-2xl font-semibold">Duplicate Document</p>
                </div>
                <div className="flex flex-col gap-5 w-full">
                  <Input
                    isRequired
                    type="text"
                    label="New Document Title"
                    placeholder="e.g. GreenLeaf Document - Copy"
                    className="w-full"
                    value={documentTitle}
                    onChange={(e) => setDocumentTitle(e.target.value)}
                    autoFocus={true}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        duplicateDocument()
                      }
                    }}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="default" variant="light" onPress={onClose}>
                Cancel
              </Button>
              <Button variant={'solid'} onPress={duplicateDocument} isDisabled={duplicatingDocument}>
                {duplicatingDocument && <Spinner size={'sm'} />}
                {' Duplicate'}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
