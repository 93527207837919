import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Chip, Table, TableBody, TableHeader, TableCell, TableRow, TableColumn, getKeyValue } from '@nextui-org/react'

export default function Audit() {
  const { t } = useTranslation()

  const rows = [
    { key: '30', name: 'James Smith', action: 'Edited Profile', timestamp: '2024-06-11 11:25:16' },
    { key: '8', name: 'James Smith', action: 'Viewed Report', timestamp: '2024-06-10 21:58:16' },
    { key: '1', name: 'Patricia Miller', action: 'Logged Out', timestamp: '2024-06-10 08:14:15' },
    { key: '2', name: 'Patricia Miller', action: 'Viewed Report', timestamp: '2024-06-09 03:00:15' },
    { key: '6', name: 'Patricia Miller', action: 'Logged Out', timestamp: '2024-06-09 02:56:15' },
    { key: '27', name: 'Tony Reichert', action: 'Viewed Report', timestamp: '2024-06-08 21:40:16' },
    { key: '18', name: 'Umay Johnson', action: 'Logged In', timestamp: '2024-06-08 18:07:16' },
    { key: '13', name: 'Tony Reichert', action: 'Logged Out', timestamp: '2024-06-06 22:05:15' },
    { key: '11', name: 'Sandra Adams', action: 'Deleted Record', timestamp: '2024-06-05 15:46:15' },
    { key: '29', name: 'Patricia Miller', action: 'Edited Profile', timestamp: '2024-06-05 07:18:16' },
    { key: '16', name: 'Tony Reichert', action: 'Edited Profile', timestamp: '2024-06-05 01:29:15' },
    { key: '28', name: 'Tony Reichert', action: 'Edited Profile', timestamp: '2024-06-04 23:58:16' },
    { key: '11', name: 'James Smith', action: 'Logged Out', timestamp: '2024-06-04 05:44:16' },
    { key: '13', name: 'Umay Johnson', action: 'Deleted Record', timestamp: '2024-06-03 03:57:16' },
    { key: '9', name: 'Tony Reichert', action: 'Logged In', timestamp: '2024-06-02 16:20:15' },
    { key: '9', name: 'James Smith', action: 'Logged In', timestamp: '2024-06-02 03:08:16' },
    { key: '20', name: 'Sandra Adams', action: 'Viewed Report', timestamp: '2024-06-01 17:09:15' },
    { key: '12', name: 'Umay Johnson', action: 'Logged Out', timestamp: '2024-05-30 16:28:15' },
    { key: '10', name: 'Umay Johnson', action: 'Logged In', timestamp: '2024-05-30 08:42:15' },
    { key: '15', name: 'Tony Reichert', action: 'Viewed Report', timestamp: '2024-05-29 09:41:15' },
    { key: '17', name: 'Tony Reichert', action: 'Viewed Report', timestamp: '2024-05-27 20:08:16' },
    { key: '14', name: 'Tony Reichert', action: 'Viewed Report', timestamp: '2024-05-26 19:15:16' },
    { key: '17', name: 'Umay Johnson', action: 'Deleted Record', timestamp: '2024-05-24 21:21:15' },
    { key: '7', name: 'Tony Reichert', action: 'Logged In', timestamp: '2024-05-24 12:42:16' },
    { key: '26', name: 'Patricia Miller', action: 'Logged Out', timestamp: '2024-05-24 01:47:16' },
    { key: '25', name: 'Tony Reichert', action: 'Viewed Report', timestamp: '2024-05-23 00:13:16' },
    { key: '19', name: 'Sandra Adams', action: 'Edited Profile', timestamp: '2024-05-22 06:28:16' },
    { key: '21', name: 'James Smith', action: 'Logged In', timestamp: '2024-05-21 22:45:16' },
    { key: '6', name: 'Umay Johnson', action: 'Logged Out', timestamp: '2024-05-21 08:29:16' },
    { key: '23', name: 'Patricia Miller', action: 'Logged Out', timestamp: '2024-05-21 06:03:16' },
    { key: '12', name: 'Patricia Miller', action: 'Viewed Report', timestamp: '2024-05-20 20:51:16' },
    { key: '15', name: 'Sandra Adams', action: 'Logged Out', timestamp: '2024-05-19 06:55:16' },
    { key: '24', name: 'James Smith', action: 'Edited Profile', timestamp: '2024-05-19 00:44:16' },
    { key: '16', name: 'Patricia Miller', action: 'Logged In', timestamp: '2024-05-17 15:34:16' },
    { key: '7', name: 'James Smith', action: 'Deleted Record', timestamp: '2024-05-17 08:46:15' },
    { key: '3', name: 'Tony Reichert', action: 'Logged Out', timestamp: '2024-05-17 07:57:15' },
    { key: '19', name: 'James Smith', action: 'Viewed Report', timestamp: '2024-05-15 20:58:15' },
    { key: '20', name: 'Tony Reichert', action: 'Logged In', timestamp: '2024-05-15 14:06:16' },
    { key: '4', name: 'James Smith', action: 'Viewed Report', timestamp: '2024-05-15 13:38:15' },
    { key: '18', name: 'Patricia Miller', action: 'Logged Out', timestamp: '2024-05-14 22:22:15' },
    { key: '5', name: 'Umay Johnson', action: 'Viewed Report', timestamp: '2024-05-14 11:49:15' },
    { key: '8', name: 'Umay Johnson', action: 'Deleted Record', timestamp: '2024-05-14 00:08:15' },
    { key: '22', name: 'Patricia Miller', action: 'Viewed Report', timestamp: '2024-05-13 09:44:16' },
  ]

  const columns = [
    {
      key: 'name',
      label: 'NAME',
    },
    {
      key: 'action',
      label: 'ACTION',
    },
    {
      key: 'timestamp',
      label: 'TIMESTAMP',
    },
  ]

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <div className="flex flex-col w-full h-screen p-3 overflow-y-auto">
        <div className="flex w-full pt-8 pb-6 px-2 2xl:px-8">
          <header className="flex w-full justify-between">
            <div className="flex flex-col">
              <div className="flex place-items-center">
                <h1 className="text-xl font-bold text-default-900 lg:text-3xl mr-2">Audit logs</h1>
                <Chip color="secondary">Coming soon</Chip>
              </div>
              <p className="text-small text-default-700 lg:text-medium">
                Review detailed logs of system, team activities, and changes.
              </p>
            </div>
          </header>
        </div>

        <div className="flex flex-col gap-3 w-full px-2 2xl:px-8 max-w-screen-md blur-sm">
          <Table
            aria-label="Powerdocsaudit log data"
            isHeaderSticky
            classNames={{ base: 'max-h-[520px] overflow-scroll' }}
          >
            <TableHeader columns={columns}>
              {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
            </TableHeader>
            <TableBody items={rows}>
              {(item) => (
                <TableRow key={item.key}>
                  {(columnKey) => <TableCell>{getKeyValue(item, columnKey)}</TableCell>}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  )
}
