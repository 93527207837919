import {
  Button,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
} from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWorkspaceContextData } from 'src/context/workspace'
import api from 'src/lib/api'

export default function DuplicateClientModal({
  openedDuplicateClient,
  onOpenDuplicateClientChange,
  clientToEdit,
}: {
  openedDuplicateClient: boolean
  onOpenDuplicateClientChange: (isOpen: boolean) => void
  clientToEdit: any
}) {
  const [duplicatingClient, setDuplicatingClient] = useState<boolean>(false)
  const { addClient } = useWorkspaceContextData()
  const [withProject, setWithProject] = useState<boolean>(true)
  const [clientName, setClientName] = useState('')
  const navigate = useNavigate()
  const duplicateClient = async () => {
    setDuplicatingClient(true)
    // TODO: Duplicate client here
    const res = await api.post('/clients/' + clientToEdit.uuid + '/duplicate', {
      newName: clientName,
      withProject,
    })
    if (res.data?.uuid) {
      addClient?.(res.data)
    }
    onOpenDuplicateClientChange(false)
    setDuplicatingClient(false)
    navigate(`/client/${res.data?.uuid}`)
  }
  useEffect(() => {
    if (clientToEdit) {
      setClientName(clientToEdit.name)
    }
  }, [clientToEdit])
  return (
    <Modal size={'3xl'} isOpen={openedDuplicateClient} onOpenChange={onOpenDuplicateClientChange}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1"></ModalHeader>
            <ModalBody>
              <div className="flex flex-col grow gap-7">
                <div className="flex flex-col items-center">
                  <p className="text-2xl font-semibold">Duplicate client</p>
                  <p className="text-base text-center">
                    If you want to proceed with this action You have the option to Duplicate the client with all
                    projects and document or just the client.
                  </p>
                </div>
                <div className="flex flex-col gap-5 w-full">
                  <Input
                    isRequired
                    type="text"
                    label="Client New name"
                    placeholder="e.g. GreenLeaf workspace - Copy"
                    className="w-full"
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                    autoFocus={true}
                  />
                  <Checkbox isSelected={withProject} onValueChange={setWithProject}>
                    Duplicate Client with Projects and Documents
                  </Checkbox>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="default" variant="light" onPress={onClose}>
                Cancel
              </Button>
              <Button variant={'solid'} onPress={duplicateClient} isDisabled={duplicatingClient}>
                {duplicatingClient && <Spinner size={'sm'} />}
                {' Duplicate'}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
