import { useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import api from 'src/lib/api'
import { Button, Chip, Divider, RadioGroup, Select, SelectItem, Spinner, useDisclosure } from '@nextui-org/react'
import { useWorkspaceContextData } from 'src/context/workspace'
import { SubscribeLimitDocument } from 'src/components/modals/subscribe-limit-document'
import { Step, Stepper } from 'react-form-stepper'
import StepWizard from 'react-step-wizard'
import { CustomRadio } from 'src/pages/ursList/CreateUrdWindow'
import Mixpanel from 'src/lib/mixpanel'
import { useSubscriptionContext } from 'src/context/subscription'
import { useSocketContextData } from 'src/context/socket'

const inActiveButtonClass = 'bg-transparent text-white'
const activeButtonClass = 'text-success-500 font-bold bg-white rounded-xl'

function Step1(props) {
  const [client, setClient] = useState<any>(props.formData?.client ?? null)
  const [projectUUID, setProjectUUID] = useState<string>(props.formData?.projectUUID ?? '')
  const { clientsList, activeWorkspace, loadMyClient } = useWorkspaceContextData()
  const { draft } = props
  return (
    <div>
      <h1 className={'text-xl text-center'}>{draft.title}</h1>
      <p className="pt-0 pb-4 text-sm text-center opacity-60 italic pl-6 pr-6 leading-5 text-default-500">
        {draft.description}
      </p>
      <Divider />
      <div className={'flex flex-col ga-10 pt-4'}>
        <h2 className={'text-xl'}>Project overview</h2>
        <p className="pt-0 pb-4 text-sm opacity-60 leading-5 text-default-500">
          Select the client and the project you will be writing your document for
        </p>
        <div className={'flex flex-row gap-3'}>
          <Select
            className="max-w-md"
            label="Select Client"
            selectedKeys={[client?.uuid]}
            onChange={(e) => {
              const clientIndex = clientsList.findIndex((item) => item.uuid === e.target.value)
              if (clientIndex === -1) {
                setClient(null)
              } else {
                setClient(clientsList[clientIndex])
              }
              setProjectUUID('')
            }}
          >
            {clientsList.map((client) => (
              <SelectItem key={client.uuid}>{client.name}</SelectItem>
            ))}
          </Select>
          {client && (
            <Select
              selectedKeys={[projectUUID]}
              className="max-w-md"
              label="Select Project"
              onChange={(e) => setProjectUUID(e.target.value)}
            >
              {(client.proposalProjects ?? []).map((project: any) => (
                <SelectItem key={project.uuid}>{project.name}</SelectItem>
              ))}
            </Select>
          )}
        </div>
        <div className="flex justify-end gap-5">
          <Button
            color={'secondary'}
            onPress={async () => {
              props.onSubmit({
                ...props.formData,
                client,
                projectUUID,
              })
              props.nextStep()
            }}
            isDisabled={!projectUUID}
            className={'pl-10 pr-10'}
            style={{
              backgroundColor: 'linear-gradient(0deg, #3D969E 12.9%, #65D0CA 85.52%)',
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}

function Step2(props) {
  const [scopeOfProject, setScopeOfProject] = useState<string[]>([])
  const [scopesLoading, setScopesLoading] = useState<boolean>(false)
  const [documentType, setDocumentType] = useState<string>('PROPOSAL')
  const [documentLength, setDocumentLength] = useState<string>('MEDIUM')
  const [paymentTerms, setPaymentTerms] = useState<string>('FIXED')
  const [generating, setGenerating] = useState<boolean>(false)
  const { activeWorkspace, loadMyClient } = useWorkspaceContextData()
  const { getUserSubscription } = useSubscriptionContext()
  const { io } = useSocketContextData()
  const { draft, scopes, formData, openSubscriptionModal } = props
  const navigate = useNavigate()
  const generateDocument = async () => {
    setGenerating(true)
    try {
      const res = await io.emitWithAck('createUrs', {
        workspaceUUID: activeWorkspace,
        scope: scopeOfProject.join('\n'),
        documentLength,
        documentType,
        projectUUID: formData?.projectUUID,
        token: localStorage.getItem('accessToken'),
        title: draft.title,
        description: draft.description,
      })
      console.log(res)
      // TODO reload client
      loadMyClient?.(activeWorkspace)
      if (res.error) {
        setTimeout(() => openSubscriptionModal(), 500)
      } else {
        getUserSubscription?.()
        navigate(`/client/${formData?.client?.uuid}/document/${res.uuid}`)

        // Mixpanel track ing for doc wizard step
        Mixpanel.track('Document wizard step completed', {
          step_number: 3,
          step_name: 'Budget & terms',
        })

        Mixpanel.track('Document created', {
          document_id: res.uuid,
        })
      }
    } catch (error) {
      console.error('Document creation failed', error)
      Mixpanel.track('Document creation error', {
        error_message: error,
      })
    } finally {
      setGenerating(false)
    }
  }
  return (
    <div className={'flex flex-col  gap-4'}>
      <div className="flex flex-col">
        <h2 className={'text-2xl'}>Project overview</h2>
        <p className="pt-0 pb-4 text-base leading-5 text-default-500">
          Provide a brief description of your project, including the main tasks and expected results.
        </p>
        <div className={'gap-2'}>
          {((scopes ?? []).length === 0 || scopesLoading) && (
            <div className={'w-full h-full justify-center flex gap-2'}>
              <div className={'flex flex-row gap-2 h-fit '}>
                <Spinner size={'sm'} />
                <span className={'font-light italic opacity-40 text-default-500 text-sm'}>
                  Loading scope of work...
                </span>
              </div>
            </div>
          )}
          {(scopes || []).map((scope: string) => (
            <Button
              key={scope}
              onClick={() => {
                setScopeOfProject((p) => {
                  const prev = [...p]
                  const indexOfPrev = prev.indexOf(scope)
                  if (indexOfPrev > -1) {
                    prev.splice(indexOfPrev, 1)
                    return prev
                  } else {
                    prev.push(scope)
                    return prev
                  }
                })
              }}
              className={
                scopeOfProject.indexOf(scope) > -1 ? 'p-2 m-1 text-white font-medium' : 'p-2 m-1 text-black font-medium'
              }
              variant={scopeOfProject.indexOf(scope) > -1 ? 'solid' : 'bordered'}
              color={'secondary'}
            >
              {scope}
            </Button>
          ))}
        </div>
      </div>
      <div className="flex flex-col mt-2">
        <h2 className={'text-2xl pb-5'}>Type of document</h2>
        <div className={'flex flex-row justify-evenly gap-5'}>
          <div
            className={`flex flex-col flex-1 cursor-pointer justify-evenly border-1 rounded-xl text-center p-5 ${
              documentType === 'PROPOSAL' ? 'bg-teal-100' : ''
            }`}
            onClick={() => setDocumentType('PROPOSAL')}
          >
            <div>
              <h3 className={'text-2xl font-bold'}>Proposal</h3>
              <span className={'text-default-500 font-light text-sm'}>Proposal & Offer</span>
            </div>
            <p className={'pt-5 pb-5 font-small'}>
              A formal offer to solve a problem or deliver a project, detailing the approach, timeline, and estimated
              cost.
            </p>
            <Button
              variant={documentType === 'PROPOSAL' ? 'solid' : 'bordered'}
              color={'secondary'}
              isDisabled={false}
              size={'md'}
              className={'pl-10 pr-10'}
              onClick={() => setDocumentType('PROPOSAL')}
            >
              {documentType === 'PROPOSAL' ? 'Selected' : 'Select'}
            </Button>
          </div>
          <div
            className={`flex flex-col flex-1 cursor-pointer justify-evenly border-1 rounded-xl text-center p-5 ${
              documentType === 'QUOTATION' ? 'bg-teal-100' : ''
            }`}
            onClick={() => setDocumentType('QUOTATION')}
          >
            <h3 className={'text-2xl font-bold'}>Quotation</h3>
            <span className={'text-default-500 font-light text-sm'}>Quotation sub-title</span>
            <p className={'pt-5 pb-5 font-small'}>
              Describes the needs and expectations of the user, outlining functional and technical requirements for a
              system or product.
            </p>
            <Button
              variant={documentType === 'QUOTATION' ? 'solid' : 'bordered'}
              color={'secondary'}
              isDisabled={false}
              size={'md'}
              className={'pl-10 pr-10'}
              onClick={() => setDocumentType('QUOTATION')}
            >
              {documentType === 'QUOTATION' ? 'Selected' : 'Select'}
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <h2 className={'text-xl pb-2'}>Length & formality of document</h2>
        <div className={'p-1 rounded-xl w-fit bg-success-500'}>
          <Button
            size={'sm'}
            className={documentLength === 'SHORT' ? activeButtonClass : inActiveButtonClass}
            onClick={() => setDocumentLength('SHORT')}
          >
            Short & informal
          </Button>
          <Button
            size={'sm'}
            className={documentLength === 'MEDIUM' ? activeButtonClass : inActiveButtonClass}
            onClick={() => setDocumentLength('MEDIUM')}
          >
            Medium & semi-formal
          </Button>
          <Button
            size={'sm'}
            className={documentLength === 'LONG' ? activeButtonClass : inActiveButtonClass}
            onClick={() => setDocumentLength('LONG')}
          >
            Long & formal
          </Button>
        </div>
      </div>
      <div className="flex flex-col">
        <h2 className={'text-2xl pb-5 flex items-center'}>
          Payment Terms{' '}
          <Chip className="ml-2" variant="solid" color="secondary">
            Optional
          </Chip>
        </h2>
        <div className={'flex flex-row justify-between gap-10'}>
          <RadioGroup
            orientation={'horizontal'}
            value={paymentTerms}
            onChange={(e) => {
              setPaymentTerms(e.target.value)
            }}
          >
            <CustomRadio value="FIXED">Fixed Price</CustomRadio>
            <CustomRadio value="HOURLY">Hourly</CustomRadio>
          </RadioGroup>
        </div>
      </div>
      <div className="flex justify-end gap-5">
        <Button color="secondary" variant="bordered" onPress={() => props.previousStep()} className={'pl-10 pr-10'}>
          Previous
        </Button>
        <Button
          color={'secondary'}
          onPress={async () => {
            generateDocument()
          }}
          isDisabled={generating || scopeOfProject.length === 0}
          className={'pl-10 pr-10'}
          style={{
            backgroundColor: 'linear-gradient(0deg, #3D969E 12.9%, #65D0CA 85.52%)',
          }}
        >
          Generate Document
        </Button>
      </div>
      {generating && (
        <div className={'fixed w-full h-full top-0 left-0'}>
          <div className={'bg-black/75 backdrop-blur-sm w-full h-full flex flex-col justify-center items-center gap-2'}>
            <div className={'flex flex-row gap-2 justify-center items-center'}>
              <Spinner size={'lg'} color="secondary" />
              <span className="text-white text-xl">Processing information…</span>
            </div>
            <span className={'text-white text-sm opacity-60 text-center'}>
              It can take up to 30 seconds.
              <br />
              Please wait and keep this browser tab open.
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default function DraftPage() {
  const [activeStep, setActiveStep] = useState(1)
  const [formData, setFormData] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [generating, setGenerating] = useState<boolean>(false)
  const [draft, setDraft] = useState<any>({})
  const [scopes, setScopes] = useState<string[]>([])
  const {
    isOpen: isSubscriptionModalOpen,
    onOpen: openSubscriptionModal,
    onClose: closeSubscriptionModal,
  } = useDisclosure()
  const params = useParams()
  const loadDraft = async (draftUUID: string) => {
    setLoading(true)
    const res = await api.get(`/drafts/${draftUUID}`)
    if (res.data.uuid) {
      setDraft(res.data)
      setScopes(res.data?.scopes?.split('\n') ?? [])
    }
    setLoading(false)
  }
  useEffect(() => {
    if (params.draftUUID) {
      loadDraft(params.draftUUID)
    }
  }, [params])
  const [stepWizard, setStepWizard] = useState(null)

  const assignStepWizard = (instance) => {
    setStepWizard(instance)
  }
  const handleStepChange = (e) => {
    setActiveStep(e.activeStep)
  }
  return (
    <div className="flex gap-13 w-full flex-col gap-2 pb-10 max-w-full max-h-full px-2 2xl:px-8 overflow-y-auto">
      {loading ? (
        <div className={'flex flex-col justify-center items-center'}>
          <Spinner size={'lg'} />
          <span>Loading details</span>
        </div>
      ) : (
        <div className={'card shadow-2xl rounded-lg m-4 p-10'}>
          <Stepper
            activeStep={activeStep}
            //@ts-ignore
            styleConfig={{
              activeBgColor: '#71717A',
              completedBgColor: '#65d0ca',
              inactiveBgColor: '#71717A',
            }}
            //@ts-ignore
            connectorStyleConfig={{
              disabledColor: '#71717A',
              activeColor: '#65d0ca',
              completedColor: '#65d0ca',
            }}
          >
            <Step label="Project Details" />
            <Step label="Project Scope" />
          </Stepper>
          <StepWizard isLazyMount={true} instance={assignStepWizard} onStepChange={handleStepChange}>
            <Step1 draft={draft} formData={formData} onSubmit={(value: any) => setFormData(value)} />
            <Step2 scopes={scopes} draft={draft} formData={formData} openSubscriptionModal={openSubscriptionModal} />
          </StepWizard>

          <SubscribeLimitDocument isOpen={isSubscriptionModalOpen} onClose={closeSubscriptionModal} />
        </div>
      )}
    </div>
  )
}
