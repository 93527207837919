'use client'

import {
  Accordion,
  AccordionItem,
  Button,
  type ListboxProps,
  type ListboxSectionProps,
  type Selection,
  Spinner,
} from '@nextui-org/react'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Listbox, Tooltip, ListboxItem, ListboxSection } from '@nextui-org/react'
import { Icon } from '@iconify/react'

import { cn } from '../sidebar/cn'
import { useSocketContextData } from 'src/context/socket'
import toast from 'react-hot-toast'
import { ArrowRightIcon } from 'lucide-react'

export enum SidebarItemType {
  Nest = 'nest',
}

export type SidebarItem = {
  key: string
  title: string
  icon?: string
  link?: string
  rightIcon?: string
  onClick?: () => void
  type?: SidebarItemType.Nest
  startContent?: React.ReactNode
  endContent?: React.ReactNode
  items?: SidebarItem[]
  className?: string
  isLoading?: boolean
  isEmpty?: boolean
}

export type SidebarProps = Omit<ListboxProps<SidebarItem>, 'children'> & {
  items: SidebarItem[]
  isLoading: boolean
  isCompact?: boolean
  hideEndContent?: boolean
  iconClassName?: string
  sectionClasses?: ListboxSectionProps['classNames']
  classNames?: ListboxProps['classNames']
  defaultSelectedKey: string
  onSelect?: (key: string) => void
}

const Sidebar = React.forwardRef<HTMLElement, SidebarProps>(
  (
    {
      items,
      isCompact,
      defaultSelectedKey,
      onSelect,
      hideEndContent,
      sectionClasses: sectionClassesProp = {},
      itemClasses: itemClassesProp = {},
      iconClassName,
      classNames,
      className,
      isLoading,
      ...props
    },
    ref,
  ) => {
    const [selected, setSelected] = React.useState<React.Key>(defaultSelectedKey)

    const location = useLocation()

    const getSelectedKey = () => {
      const currentPath = location.pathname
      let selectedItem = null

      items.forEach((item) => {
        if (item.items) {
          const subItem = item.items.find((sub) => {
            return currentPath?.startsWith(sub.link) && sub.link !== '/'
          })
          if (subItem) {
            selectedItem = subItem
          }
        } else {
          if (currentPath?.startsWith(item.link) && item.link !== '/') {
            selectedItem = item
          }
        }
      })
      return selectedItem ? selectedItem.key : 'home'
    }

    useEffect(() => {
      setSelected(getSelectedKey())
    }, [location])

    const sectionClasses = {
      ...sectionClassesProp,
      base: cn(sectionClassesProp?.base, 'w-full', {
        'p-0 max-w-[44px]': isCompact,
      }),
      group: cn(sectionClassesProp?.group, {
        'flex flex-col gap-1': isCompact,
      }),
      heading: cn(
        sectionClassesProp?.heading,
        {
          hidden: isCompact,
        },
        'pl-0',
      ),
    }

    const itemClasses = {
      ...itemClassesProp,
      base: cn(itemClassesProp?.base, {
        'w-11 h-11 gap-0 p-0': isCompact,
      }),
    }

    const navigate = useNavigate()

    const renderNestItem = React.useCallback(
      (item: SidebarItem) => {
        const isNestType = item.items && item.items?.length > 0 && item?.type === SidebarItemType.Nest

        if (isNestType) {
          // Is a nest type item , so we need to remove the href
          delete item.link
        }
        return (
          <ListboxItem
            {...item}
            key={item.key}
            onPress={() => (item.onClick ? item.onClick() : navigate(item.link ?? '#'))}
            classNames={{
              base: cn(
                {
                  'h-auto p-0': !isCompact && isNestType,
                },
                {
                  'inline-block w-11': isCompact && isNestType,
                },
              ),
            }}
            endContent={isCompact || hideEndContent ? null : item.endContent ?? null}
            startContent={
              isCompact || isNestType ? null : item.icon ? (
                <Icon
                  className={cn('text-default-500 group-data-[selected=true]:text-foreground', iconClassName)}
                  icon={item.icon}
                  width={24}
                />
              ) : (
                item.startContent ?? null
              )
            }
            title={isCompact || isNestType ? null : item.title}
          >
            {isCompact ? (
              <Tooltip content={item.title} placement="right">
                <div className="flex w-full items-center justify-center">
                  {item.icon ? (
                    <Icon
                      className={cn('text-default-500 group-data-[selected=true]:text-foreground', iconClassName)}
                      icon={item.icon}
                      width={24}
                    />
                  ) : (
                    item.startContent ?? null
                  )}
                </div>
              </Tooltip>
            ) : null}
            {!isCompact && isNestType ? (
              <Accordion className={'p-0'}>
                <AccordionItem
                  key={item.key}
                  aria-label={item.title}
                  classNames={{
                    heading: 'pr-3',
                    trigger: 'p-0',
                    content: 'py-0 pl-4',
                  }}
                  title={
                    item.icon ? (
                      <div className={'flex h-11 items-center gap-2 px-2 py-1.5'}>
                        <Icon
                          className={cn('text-default-500 group-data-[selected=true]:text-foreground', iconClassName)}
                          icon={item.icon}
                          width={24}
                        />
                        <span className="text-small font-medium text-default-500 group-data-[selected=true]:text-foreground">
                          {item.title}
                        </span>
                      </div>
                    ) : (
                      item.startContent ?? null
                    )
                  }
                >
                  {item.items && item.items?.length > 0 ? (
                    <Listbox
                      className={'mt-0.5'}
                      classNames={{
                        list: cn('border-l border-default-200 pl-4'),
                      }}
                      items={item.items}
                      variant="flat"
                    >
                      {item.items.map(renderItem)}
                    </Listbox>
                  ) : (
                    renderItem(item)
                  )}
                </AccordionItem>
              </Accordion>
            ) : null}
          </ListboxItem>
        )
      },
      [isCompact, hideEndContent, iconClassName, items, selected],
    )

    const renderItem = (item: SidebarItem) => {
      const isNestType = item.items && item.items?.length > 0 && item?.type === SidebarItemType.Nest

      if (isNestType) {
        return renderNestItem(item)
      }
      return (
        <ListboxItem
          {...item}
          key={item.key}
          endContent={isCompact || hideEndContent ? null : item.endContent ?? null}
          startContent={
            isCompact ? null : item.icon ? (
              <Icon
                className={cn('text-default-500 group-data-[selected=true]:text-foreground', iconClassName)}
                icon={item.icon}
                width={24}
              />
            ) : (
              item.startContent ?? null
            )
          }
          onPress={() => navigate(item.link ?? '#')}
          textValue={item.title}
          title={isCompact ? null : item.title}
          className={'mb-1 w-full px-3'}
        >
          {isCompact ? (
            <Tooltip content={item.title} placement="right">
              <div className="flex w-full items-center justify-center">
                {item.icon ? (
                  <Icon
                    className={cn('text-default-500 group-data-[selected=true]:text-foreground', iconClassName)}
                    icon={item.icon}
                    width={24}
                  />
                ) : (
                  item.startContent ?? null
                )}
              </div>
            </Tooltip>
          ) : null}
        </ListboxItem>
      )
    }

    const { io } = useSocketContextData()
    const onIoProjectError = (data: any) => {
      const { title, meetingUUID } = data
      toast.error(
        (t) => (
          <div
            className={'flex flex-row cursor-pointer items-center'}
            onClick={() => {
              toast.dismiss(t.id)
              navigate(`/project/${meetingUUID}`)
            }}
          >
            <span className={'flex flex-1'}>{title} deployment failed.</span>
            <Button
              color="danger"
              size="sm"
              className={'text-small'}
              endContent={<ArrowRightIcon size={14} />}
              onClick={() => {
                toast.dismiss(t.id)
                navigate('/project/')
              }}
            />
          </div>
        ),
        {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        },
      )
    }
    useEffect(() => {
      io?.on('projectError', onIoProjectError)
      return () => {
        io?.off('projectError', onIoProjectError)
      }
    }, [io])
    return (
      <Listbox
        key={isCompact ? 'compact' : 'default'}
        ref={ref}
        hideSelectedIcon
        as="nav"
        className={cn('list-none', className)}
        classNames={{
          ...classNames,
          list: cn('items-center', classNames?.list),
        }}
        color="secondary"
        itemClasses={{
          ...itemClasses,
          base: cn('px-3 min-h-11 rounded-large h-[44px] data-[selected=true]:bg-default-100', itemClasses?.base),
          title: cn(
            'text-small font-medium text-default-500 group-data-[selected=true]:text-foreground',
            itemClasses?.title,
          ),
        }}
        items={items}
        selectedKeys={[selected] as unknown as Selection}
        selectionMode="single"
        variant="flat"
        onSelectionChange={(keys) => {
          const key = Array.from(keys)[0]
          if (key) {
            setSelected(key as React.Key)
            onSelect?.(key as string)
          }
        }}
        {...props}
      >
        {(item) => {
          return item.items && item.items?.length > 0 && item?.type === SidebarItemType.Nest ? (
            renderNestItem(item)
          ) : item.items ? (
            <ListboxSection
              key={item.key}
              classNames={sectionClasses}
              showDivider={isCompact}
              title={
                <div>
                  <div
                    className={'flex text-sm flex-row justify-between items-center cursor-pointer pl-1 pt-6'}
                    onClick={item.onClick}
                  >
                    <span className={'flex text-foreground'}>{item.title}</span>
                    {item.rightIcon && <Icon icon={item.rightIcon} width="18" />}
                  </div>
                  {item.isLoading && (
                    <div className={'w-full h-full justify-center flex gap-2 pt-4 pb-2'}>
                      <div className={'flex flex-row gap-2 h-fit '}>
                        <Spinner size={'sm'} />
                        <span className={'font-light italic opacity-40 text-default-500 text-sm'}>Loading clients</span>
                      </div>
                    </div>
                  )}
                  {item.isEmpty && (
                    <div className={'w-full h-full justify-center flex gap-2 pt-4 pb-2'}>
                      <div className={'flex flex-row gap-2 h-fit '}>
                        <span className={'font-light italic opacity-40 text-center text-default-500 text-sm'}>
                          No client in this workspace
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              }
            >
              {item.items.map(renderItem)}
            </ListboxSection>
          ) : (
            renderItem(item)
          )
        }}
      </Listbox>
    )
  },
)

Sidebar.displayName = 'Sidebar'

export default Sidebar
