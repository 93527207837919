import api from 'src/lib/api'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash-es'

import {
  Button,
  useDisclosure,
  Spinner,
  Table,
  TableColumn,
  TableBody,
  TableHeader,
  TableRow,
  TableCell,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Input,
  SelectItem,
  Select,
} from '@nextui-org/react'
import { Icon } from '@iconify/react'
import { useNavigate, useParams } from 'react-router-dom'
import Mixpanel from 'src/lib/mixpanel'
import { useSocketContextData } from 'src/context/socket'
import { useWorkspaceContextData } from 'src/context/workspace'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import CreateUrdWindow from 'src/pages/ursList/CreateUrdWindow'
import toast from 'react-hot-toast'
import { SubscribeLimitDocument } from 'src/components/modals/subscribe-limit-document'
import { useSubscriptionContext } from 'src/context/subscription'
import RenameDocumentModal from 'src/pages/ursList/RenameDocumentModal'
import DeleteDocumentModal from 'src/pages/ursList/DeleteDocumentModal'
import DuplicateDocumentModal from 'src/pages/ursList/DuplicateDocumentModal'
dayjs.extend(relativeTime)

export default function ClientDocuments() {
  const { t } = useTranslation()
  const [initialized, setInitialized] = useState<boolean>(false)
  const { activeWorkspace, checkClient } = useWorkspaceContextData()
  const [user, setUser] = useState(null)
  const loadUser = () => {
    const userString = localStorage.getItem('user')
    if (userString) {
      setUser(JSON.parse(userString))
    }
    api
      .get('/agencies/profile')
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data))
        setUser(res.data)
      })
      .catch((e) => {
        console.log('Error loading current profile')
      })
  }
  useEffect(() => {
    loadUser()
  }, [])
  const { io } = useSocketContextData()
  // Create new client modal and listen to "new" in the URL
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isSubscriptionModalOpen,
    onOpen: openSubscriptionModal,
    onClose: closeSubscriptionModal,
  } = useDisclosure()
  const handleOpen = () => {
    onOpen()
  }
  const params = useParams()
  const [project, setProject] = useState({})
  const loadProject = async () => {
    const res = await api.get(`/documents/`)
    setProject(res.data)
  }

  useEffect(() => {
    if (params.projectUUID) {
      loadProject()
    }
  }, [params])

  const [creating, setCreating] = useState<boolean>(false)

  const createNewUrs = async () => {
    Mixpanel.track('Document creation intent', { screen: 'projects' })
    onOpen()
  }

  const [sendingEmail, setSendingEmail] = useState<boolean>(false)
  const resendVerification = async () => {
    if (!sendingEmail) {
      setSendingEmail(true)
      const res = await api.post('/auth/resend')
      setSendingEmail(false)
      toast.success('Verification email has been sent successfully')
    }
  }
  const { currentLimitations, getUserSubscription } = useSubscriptionContext()
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onDeleteOpenChange } = useDisclosure()
  const { isOpen: isDuplicateOpen, onOpen: onDuplicateOpen, onOpenChange: onDuplicateOpenChange } = useDisclosure()
  const { isOpen: isEditOpen, onOpen: onOpenEditProject, onOpenChange: onEditOpenChange } = useDisclosure()
  const { isOpen: isOpenAssignClient, onOpen: onOpenAssignClient, onOpenChange: onOpenedAssignClient } = useDisclosure()
  const [documentToEdit, setDocumentToEdit] = useState<any>({})
  const [assigningDocument, setAssigningDocument] = useState<boolean>(false)
  const { clientsFullList } = useWorkspaceContextData()
  const updateDocuments = async () => {
    return loadDocuments(status, name)
  }
  useEffect(() => {
    getUserSubscription?.()
  }, [])
  const onAssignToClient = async (documentUUID: string, clientUUID: string, tempClient?: any) => {
    const client = clientsFullList.filter((c) => c.uuid === clientUUID).pop()
    if (client || tempClient) {
      setAssigningDocument(true)
      await io.emitWithAck('assignDocumentToClient', {
        token: localStorage.getItem('accessToken'),
        documentUUID,
        clientUUID,
      })
      setAssigningDocument(false)
      setDocuments((prev) => {
        const copyDocs = [...prev]
        const documentIndex = copyDocs.findIndex((d) => d.uuid === documentUUID)
        if (documentIndex > -1) {
          copyDocs[documentIndex].Client = client ?? tempClient
        }
        return copyDocs
      })
    }
  }
  const getPhaseClass = (phase: string) => {
    switch (phase) {
      case 'Submitted':
        return 'cursor-pointer text-center pt-1 pb-1 w-full bg-success-500 rounded-xl text-white'
      case 'Accepted':
        return 'cursor-pointer text-center pt-1 pb-1 w-full bg-success-200 rounded-xl text-white'
      case 'Follow-up':
        return 'cursor-pointer text-center pt-1 pb-1 w-full bg-warning-400 rounded-xl text-white'
      case 'Declined':
        return 'cursor-pointer text-center pt-1 pb-1 w-full bg-danger-200 rounded-xl text-white'
      default:
        return ''
    }
  }
  const [filterValue, setFilterValue] = useState<string>('')
  const onClear = () => {
    setFilterValue('')
  }
  const [filterStatus, setFilterStatus] = useState(new Set(['Accepted']))
  const statusList = [
    { key: 'Submitted', label: 'Submitted' },
    { key: 'Accepted', label: 'Accepted' },
    { key: 'Follow-up', label: 'Follow-up' },
    { key: 'Declined', label: 'Declined' },
  ]
  const topContent = React.useMemo(() => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end">
          <div className="flex gap-1 w-full">
            <Input
              isClearable
              className="w-full sm:max-w-[44%] md:max-w-[20%]"
              placeholder="Search by name..."
              startContent={<Icon name={'lucide:search'} />}
              value={filterValue}
              onClear={() => onClear()}
              onValueChange={setFilterValue}
            />
            <Select
              className="max-w-[150px]"
              placeholder="Filter by project phase"
              selectedKeys={filterStatus}
              selectionMode="multiple"
              label={''}
              renderValue={() => (filterStatus.size === 4 ? 'All' : `Filter (${filterStatus.size})`)}
              onSelectionChange={setFilterStatus}
            >
              {statusList.map((s) => (
                <SelectItem aria-labelbyzz={s.key} key={s.key}>
                  {s.label}
                </SelectItem>
              ))}
            </Select>
          </div>
          <div className="flex gap-3">
            <Button
              onClick={() => {
                createNewUrs()
              }}
              color="secondary"
              size="md"
              radius="md"
              isDisabled={creating || (!currentLimitations?.isPro && !currentLimitations?.canAddDocument)}
              startContent={
                creating ? (
                  <Spinner size={'md'} />
                ) : (
                  <Icon className="flex-none" icon="solar:add-circle-linear" width={20} />
                )
              }
            >
              Create document
            </Button>
          </div>
        </div>
      </div>
    )
  }, [filterStatus, filterValue, currentLimitations])
  const [documents, setDocuments] = useState<any[]>([])
  const { clientUUID } = useParams()
  const loadDocuments = useCallback(
    async (status: string | null, name: string) => {
      const res = await api.get(`/urs/list/${activeWorkspace}/client/${clientUUID}`, {
        params: {
          status,
          name,
        },
      })
      setDocuments(res.data)
    },
    [activeWorkspace, clientUUID],
  )
  const debouncedLoadDocuments = useCallback(
    debounce((status: string, name: string) => {
      loadDocuments(status, name)
    }, 300), // Adjust debounce delay as needed
    [loadDocuments],
  )
  useEffect(() => {
    debouncedLoadDocuments(Array.from(filterStatus).join(','), filterValue)
    // Cleanup to cancel debounce on unmount or updates
    return () => debouncedLoadDocuments.cancel()
  }, [filterStatus, filterValue, debouncedLoadDocuments])
  const updateDocumentStatus = async (uuid: string, phase: string) => {
    const resStatus = await io.emitWithAck('updateDocumentStatus', {
      token: localStorage.getItem('accessToken'),
      uuid,
      phase,
    })
    const doc = documents.filter((doc) => doc.uuid === uuid).pop()
    const clientUUID = doc.Client?.uuid
    if (clientUUID) {
      checkClient?.(clientUUID, uuid, phase)
    }
  }
  return (
    <>
      <CreateUrdWindow
        isOpen={isOpen}
        onClose={onClose}
        projectUUID={params.projectUUID}
        showSubscriptionError={() => {
          setTimeout(() => openSubscriptionModal(), 1000)
        }}
      />
      <SubscribeLimitDocument isOpen={isSubscriptionModalOpen} onClose={closeSubscriptionModal} />
      <div className="flex flex-col w-full h-screen p-3">
        <div className="flex w-full pt-8 pb-6 px-2 2xl:px-8">
          <header className="flex w-full justify-between">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold text-default-900 lg:text-3xl">Submissions</h1>
            </div>
          </header>
        </div>

        {!user?.isActive && (
          <div className={'flex w-full p-2 mb-3 rounded-xl bg-warning-100'}>
            <p className={'text-sm flex flex-row gap-1 flex-1 '}>
              {'⚠️ You need to verify your account in order to use this feature.'}
              <p className={'cursor-pointer underline'} onClick={resendVerification}>
                Click here to resend verification email.
              </p>
            </p>
            {sendingEmail && <Spinner size={'sm'} />}
          </div>
        )}
        <div className="flex gap-13 w-full flex-col gap-2 pb-10 max-w-full max-h-full px-2 2xl:px-8 overflow-y-auto">
          <Table aria-label="Document List" topContent={topContent} topContentPlacement="outside">
            <TableHeader>
              <TableColumn className={'text-black bg-[#C3E3E8]'}>Project Name</TableColumn>
              <TableColumn className={'text-black bg-[#C3E3E8]'}>Project Phase</TableColumn>
              <TableColumn className={'text-black bg-[#C3E3E8]'}>Platform</TableColumn>
              <TableColumn className={'text-black bg-[#C3E3E8]'}>Date</TableColumn>
              <TableColumn className={'text-black bg-[#C3E3E8]'}>Actions</TableColumn>
            </TableHeader>
            <TableBody>
              {documents?.map((item, index) => (
                <TableRow key={item.uuid}>
                  <TableCell
                    className={'cursor-pointer flex flex-row gap-2 items-center align-middle'}
                    onClick={() => navigate(`/client/${project.Client?.uuid ?? 'unknown'}/document/${item.uuid}`)}
                  >
                    <Icon
                      icon={item.documentType === 'PROPOSAL' ? 'lucide:heart-handshake' : 'lucide:file-signature'}
                      width={32}
                      className={'opacity-20'}
                    />{' '}
                    <div className={'flex flex-col'}>
                      <span className={'text-black text-md'}>{item?.title}</span>
                      <span className={'text-grey text-xs opacity-20'}>
                        {`Edited ${dayjs(item?.updatedAt).fromNow()}`}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell className={'capitalize'}>
                    <Dropdown
                      classNames={{
                        content: 'rounded-xl border border-1 border-success-500 min-w-[200px]',
                      }}
                    >
                      <DropdownTrigger>
                        <span className={getPhaseClass(item.phase)}>{item.phase}</span>
                      </DropdownTrigger>
                      <DropdownMenu aria-label="client Actions">
                        <DropdownItem
                          key="submitted"
                          onClick={() => {
                            setDocuments((prev) => {
                              const copyP = [...prev]
                              copyP[index].phase = 'Submitted'
                              return copyP
                            })
                            updateDocumentStatus(item.uuid, 'Submitted')
                          }}
                        >
                          <div className={'flex flex-row justify-between items-center'}>
                            <span className="flex flex-row w-full max-w-[100px] justify-center text-center pt-1 pb-1 font-bold items-center gap-2 bg-success-500 rounded-2xl">
                              <span className={'text-md font-light text-white'}>Submitted</span>
                            </span>
                            {item.phase === 'Submitted' && <Icon icon={'lucide:check'} width={20} />}
                          </div>
                        </DropdownItem>
                        <DropdownItem
                          key="accepted"
                          onClick={() => {
                            setDocuments((prev) => {
                              const copyP = [...prev]
                              copyP[index].phase = 'Accepted'
                              return copyP
                            })
                            updateDocumentStatus(item.uuid, 'Accepted')
                          }}
                        >
                          <div className={'flex flex-row justify-between items-center'}>
                            <span className="flex flex-row w-full max-w-[100px] justify-center text-center pt-1 pb-1 font-bold items-center gap-2 bg-success-200 rounded-2xl">
                              <span className={'text-md font-light text-white'}>Accepted</span>
                            </span>
                            {item.phase === 'Accepted' && <Icon icon={'lucide:check'} width={20} />}
                          </div>
                        </DropdownItem>
                        <DropdownItem
                          key={'follow-up'}
                          onClick={() => {
                            setDocuments((prev) => {
                              const copyP = [...prev]
                              copyP[index].phase = 'Follow-up'
                              return copyP
                            })
                            updateDocumentStatus(item.uuid, 'Follow-up')
                          }}
                        >
                          <div className={'flex flex-row justify-between items-center'}>
                            <span className="flex flex-row w-full max-w-[100px] justify-center text-center pt-1 pb-1 font-bold items-center gap-2 bg-warning-200 rounded-2xl">
                              <span className={'text-md font-light text-white'}>Follow-up</span>
                            </span>
                            {item.phase === 'Follow-up' && <Icon icon={'lucide:check'} width={20} />}
                          </div>
                        </DropdownItem>
                        <DropdownItem
                          key={'declined'}
                          onClick={() => {
                            setDocuments((prev) => {
                              const copyP = [...prev]
                              copyP[index].phase = 'Declined'
                              return copyP
                            })
                            updateDocumentStatus(item.uuid, 'Declined')
                          }}
                        >
                          <div className={'flex flex-row justify-between items-center'}>
                            <span className="flex flex-row w-full max-w-[100px] justify-center text-center pt-1 pb-1 font-bold items-center gap-2 bg-danger-400 rounded-2xl">
                              <span className={'text-md font-light text-white'}>Declined</span>
                            </span>

                            {item.phase === 'Declined' && <Icon icon={'lucide:check'} width={20} />}
                          </div>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                  <TableCell className={'capitalize'}>{item.documentType?.toLowerCase()}</TableCell>
                  <TableCell className={'capitalize'}>
                    {dayjs(item.createdAt?.toLowerCase()).format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell>
                    <Dropdown
                      classNames={{
                        content: 'rounded-xl border border-1 border-success-500 min-w-[150p]',
                      }}
                    >
                      <DropdownTrigger>
                        <Button
                          variant={'light'}
                          className={'p-0 m-0 data-[hover=true]:bg-transparent'}
                          onClick={() => {}}
                        >
                          <Icon icon={'lucide:more-vertical'} />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu aria-label="client Actions">
                        <DropdownItem
                          key="edit"
                          onClick={() => {
                            setDocumentToEdit(item)
                            onEditOpenChange()
                          }}
                        >
                          <span className="flex flex-row w-full font-bold items-center gap-2">
                            <Icon icon={'lucide:edit-3'} className={'text-success-500'} />
                            <span className={'text-md font-bold'}>Rename</span>
                          </span>
                        </DropdownItem>
                        <DropdownItem
                          key="duplicate"
                          showDivider
                          onClick={() => {
                            setDocumentToEdit(item)
                            onDuplicateOpenChange()
                          }}
                        >
                          <span className="flex flex-row w-full font-bold items-center gap-2">
                            <Icon icon={'lucide:book-copy'} className={'text-success-500'} />
                            <span className={'text-md font-bold'}>Duplicate</span>
                          </span>
                        </DropdownItem>
                        <DropdownItem
                          key="delete"
                          className="text-danger"
                          color="danger"
                          onClick={() => {
                            setDocumentToEdit(item)
                            onDeleteOpenChange()
                          }}
                        >
                          <span className="flex flex-row w-full font-bold items-center gap-2">
                            <Icon icon={'lucide:trash-2'} />
                            <span className={'text-md font-bold'}>Delete</span>
                          </span>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <RenameDocumentModal
          isEditOpen={isEditOpen}
          onEditOpenChange={onEditOpenChange}
          documentToEdit={documentToEdit}
          updateDocuments={updateDocuments}
        />
        <DeleteDocumentModal
          documentToEdit={documentToEdit}
          onOpenDeleteDocumentChange={onDeleteOpenChange}
          openedDeleteDocument={isDeleteOpen}
          updateDocuments={updateDocuments}
        />
        <DuplicateDocumentModal
          openedDuplicateDocument={isDuplicateOpen}
          onOpenDuplicateDocumentChange={onDuplicateOpenChange}
          documentToEdit={documentToEdit}
          updateDocuments={updateDocuments}
        />
      </div>
    </>
  )
}
