import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardFooter, CardHeader, CardProps, ScrollShadow } from '@nextui-org/react'
import { useWorkspaceContextData } from 'src/context/workspace'
import { useNavigate, useParams } from 'react-router-dom'
import { Icon } from '@iconify/react'
import dayjs from 'dayjs'
import ClientDocuments from './ClientDocuments'

export default function ClientPage(props: CardProps) {
  const { clientsList } = useWorkspaceContextData()
  const params = useParams()
  const [activeClient, setActiveClient] = useState<any>({})

  useEffect(() => {
    if (params?.clientUUID) {
      const client = clientsList.filter((c) => c.uuid === params.clientUUID).pop()
      setActiveClient(client)
    }
  }, [clientsList, params])
  const navigate = useNavigate()

  return (
    <>
      <div className="flex flex-col w-full max-w-screen-xl min-h-screen p-3 overflow-y-auto">
        <div className="flex w-full pt-8 pb-6 px-2 2xl:px-8">
          <header className="flex w-full justify-between">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold text-default-900 lg:text-3xl">{activeClient?.name}</h1>
            </div>
          </header>
        </div>

        <div className="flex flex-col px-2 2xl:px-8">
          <div className="flex items-center gap-1">
            <p className="text-2xl text-default-800 font-medium pb-4">Recently worked on</p>
          </div>
          <div className="overflow-x-auto relative max-w-full">
            <ScrollShadow
              orientation="horizontal"
              size={50}
              hideScrollBar
              className="flex w-full max-w-full snap-x justify-start"
            >
              <div className="grid grid-flow-col auto-cols-max gap-4 w-0 pt-4 pb-4 pl-4">
                {activeClient?.URS?.map((ursDocument, index) => (
                  // {[...Array(10)].map((_, index) => ( // demo
                  <Card
                    isPressable
                    onPress={() => navigate(`/client/${activeClient?.uuid}/document/${ursDocument?.uuid}`)}
                    className="py-1 snap-start"
                  >
                    <CardHeader className="pt-2 pb-1 flex-col items-start">
                      <Icon icon="lucide:file-text" width="18" />
                    </CardHeader>
                    <CardBody className="overflow-visible pt-1 pb-2 w-[200px]">
                      <h4 className="font-medium text-medium line-clamp-2 leading-5">
                        {ursDocument?.title?.length > 0 ? ursDocument?.title : 'Untitled document'}
                      </h4>
                    </CardBody>
                    <CardFooter className="text-default-500 py-2">
                      <Icon icon="lucide:history" />
                      <small className="ml-1 truncate">{dayjs(ursDocument?.updatedAt).fromNow()}</small>
                    </CardFooter>
                  </Card>
                ))}
              </div>
            </ScrollShadow>
          </div>
        </div>
        <ClientDocuments />
      </div>
    </>
  )
}
