import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spinner } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWorkspaceContextData } from 'src/context/workspace'
import RSelect from 'react-select'
import { useSocketContextData } from 'src/context/socket'

export default function MergeWithClientModal({
  openedMergeClient,
  onOpenMergeClient,
  clientToEdit,
}: {
  openedMergeClient: boolean
  onOpenMergeClient: (isOpen: boolean) => void
  clientToEdit: any
}) {
  const [mergingClient, setMergingClient] = useState<boolean>(false)
  const { addClient, activeWorkspace, loadMyClient } = useWorkspaceContextData()
  const [clientName, setClientName] = useState('')
  useEffect(() => {
    if (clientToEdit) {
      setClientName(clientToEdit.name)
    }
  }, [clientToEdit])
  const navigate = useNavigate()
  const { clientsFullList } = useWorkspaceContextData()
  const [targetClient, setTargetClient] = useState<string>('')
  const { io } = useSocketContextData()
  const mergeClient = async () => {
    setMergingClient(true)
    const res = await io.emitWithAck('mergeClient', {
      token: localStorage.getItem('accessToken'),
      source: clientToEdit.uuid,
      target: targetClient,
      clientName,
    })
    setMergingClient(false)
    loadMyClient?.(activeWorkspace)
    onOpenMergeClient(false)
    navigate('/')
  }
  return (
    <Modal
      size={'3xl'}
      isOpen={openedMergeClient}
      onOpenChange={onOpenMergeClient}
      className="z-[1000] select-dialog"
      isDismissable={false}
      backdrop="opaque"
    >
      <div
        onClick={(e) => {
          if (e.target.getAttribute('data-slot') === 'wrapper') {
            onOpenMergeClient(false)
          }
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1"></ModalHeader>
              <ModalBody>
                <div className="flex flex-col grow gap-7">
                  <div className="flex flex-col items-center">
                    <p className="text-2xl font-semibold">Merge clients</p>
                    <p className="text-base text-center">
                      If you want to merge multiple clients under one entity, please select the client list to be merge
                      with the current client and you can specify the new name. Please Note this action is irreversible.
                    </p>
                  </div>
                  <div className="flex flex-col gap-5 w-full">
                    <Input
                      isRequired
                      type="text"
                      label="New name"
                      placeholder="e.g. GreenLeaf workspace - Copy"
                      className="w-full"
                      value={clientName}
                      onChange={(e) => setClientName(e.target.value)}
                      autoFocus={true}
                    />
                  </div>
                  <div className={' relative flex flex-col gap-1'}>
                    <span className={'text-medium ml-1 text-sm'}>Merge with</span>
                    <RSelect
                      className={'flex flex-1 w-full'}
                      onChange={(option) => setTargetClient(option.value)}
                      placeholder={`Merge ${clientToEdit?.name} with other clients`}
                      options={clientsFullList
                        .filter((item) => item.uuid !== clientToEdit.uuid)
                        .map((client) => ({
                          value: client.uuid,
                          label: client.name,
                        }))}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? '#3D969E' : '#fff',
                          color: state.isFocused ? '#fff' : '#333',
                          padding: '10px 12px',
                          cursor: 'pointer',
                          transition: 'background-color 0.2s ease',
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: '#333',
                          fontWeight: '500',
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          padding: '0',
                        }),
                        control: (base, state) => ({
                          ...base,
                          width: '100%', // Ensure the control spans the full width
                          minWidth: '100%',
                          borderRadius: '8px',
                          backgroundColor: '#fff',
                          border: state.isFocused ? '1px solid #000' : '1px solid #E0E0E0',
                          boxShadow: state.isFocused ? '0 0 4px rgba(0, 114, 245, 0.3)' : 'none',
                          padding: '4px',
                          transition: 'border 0.2s ease, box-shadow 0.2s ease',
                        }),
                        menu: (base) => ({
                          ...base,
                          width: '100%', // Dropdown menu spans full width
                          minWidth: '100%',
                          borderRadius: '8px',
                          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                          overflow: 'hidden',
                        }),
                        menuPortal: (base) => ({ ...base, zIndex: 99999 }),
                      }}
                      menuPortalTarget={window.document.querySelector('body')}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="default" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button variant={'solid'} onPress={mergeClient} isDisabled={mergingClient}>
                  {mergingClient && <Spinner size={'sm'} />}
                  {mergingClient ? ' Merging...' : ' Merge'}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </div>
    </Modal>
  )
}
