import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  Progress,
  Textarea,
  useRadio,
  VisuallyHidden,
  cn,
  RadioGroup,
  Spinner,
  ScrollShadow,
  Chip,
} from '@nextui-org/react'

import StepWizard from 'react-step-wizard'
import './StepWizard.css'

import { Stepper, Step } from 'react-form-stepper'

import { useWorkspaceContextData } from 'src/context/workspace'
import { useSocketContextData } from 'src/context/socket'
import { useNavigate, useParams } from 'react-router-dom'

import Mixpanel from 'src/lib/mixpanel'
import { useSubscriptionContext } from 'src/context/subscription'
import { Icon } from '@iconify/react'

const inActiveButtonClass = 'bg-transparent text-white'
const activeButtonClass = 'text-success-500 font-bold bg-white rounded-xl'
import CreatableSelect from 'react-select/creatable'

export const CustomRadio = (props) => {
  const {
    Component,
    children,
    isSelected,
    description,
    getBaseProps,
    getWrapperProps,
    getInputProps,
    getLabelProps,
    getLabelWrapperProps,
    getControlProps,
  } = useRadio(props)

  return (
    <Component
      {...getBaseProps()}
      className={cn(
        'group inline-flex items-center hover:opacity-70 active:opacity-50 justify-between flex-row-reverse tap-highlight-transparent',
        'max-w-[300px] cursor-pointer border-2 border-default rounded-lg gap-4 p-2',
        'data-[selected=true]:border-secondary',
      )}
    >
      <VisuallyHidden>
        <input {...getInputProps()} />
      </VisuallyHidden>
      <span
        {...getWrapperProps()}
        style={
          isSelected
            ? {
                borderColor: '#65d0ca',
              }
            : {}
        }
      >
        <span
          {...getControlProps()}
          style={
            isSelected
              ? {
                  backgroundColor: '#65d0ca',
                }
              : {}
          }
        />
      </span>
      <div {...getLabelWrapperProps()}>
        {children && <span {...getLabelProps()}>{children}</span>}
        {description && <span className="text-small text-foreground opacity-70">{description}</span>}
      </div>
    </Component>
  )
}

function Step1(props) {
  const { data, loadScope } = props
  const [clientName, setClientName] = useState<string>(data?.clientName ?? '')
  const [title, setProjectTitle] = useState<string>(data?.title ?? '')
  const [description, setDescription] = useState<string>(data?.description ?? '')
  const [showFullForm, setShowFullForm] = useState(true)
  const divRef = useRef<any>(null)
  useEffect(() => {
    const element = document.getElementById('document-wrap-container')
    if (element) {
      element.scrollTop = 0
    }
  }, [])
  const progress = useMemo(() => Math.min(((description.split(' ').length - 1) / 20) * 100, 100), [description])
  const variant = useMemo(() => (progress < 50 ? 'danger' : progress < 90 ? 'warning' : 'success'), [progress])
  const [upworkUrl, setUpworkUrl] = useState<string>('')
  const { io } = useSocketContextData()
  const [scrapping, setScrapping] = useState<boolean>(false)
  const [finishScrapping, setFinishScrapping] = useState<boolean>(false)
  const getDescription = async () => {
    setFinishScrapping(false)
    setScrapping(true)
    const res = await io.emitWithAck('getDescription', {
      url: upworkUrl,
    })
    if (res.title) setProjectTitle(res.title)
    if (res.description) {
      setDescription(res.description)
      loadScope?.(res.description)
    }
    setScrapping(false)
    setFinishScrapping(true)
    setTimeout(() => {
      setFinishScrapping(false)
      props.submitData({
        title: res.title ?? title,
        clientName,
        description: res.description ?? description,
      })
      props.nextStep()
    }, 500)
  }
  const { clientsFullList } = useWorkspaceContextData()
  const [selectedClient, setSelectedClient] = useState<{ value: string; label: string } | null>(null)
  return (
    <div className="relative flex flex-col gap-10" ref={divRef}>
      {!showFullForm && (
        <div className="flex flex-col pt-10 pb-10">
          <h2 className={'text-xl pb-2'}>Insert a link to the project description.</h2>
          <p className={'text-sm pb-2'}>
            Paste the URL of the public tender or RFQ (Request for Quotation) here.
            <br />
            Our tool will extract the necessary details to craft a document in your preferred format.
          </p>
          <div className={'flex flex-col justify-between gap-2 mt-2'}>
            <Input
              isRequired
              variant={'bordered'}
              label="Link"
              placeholder={'e.g. www.upwork.com/website-integration-project'}
              classNames={{
                label: 'text-secondary',
              }}
              isDisabled={scrapping}
              value={upworkUrl}
              onChange={(e) => setUpworkUrl(e.target.value)}
            />
            <div className={'flex flex-row justify-between gap-2 items-center'}>
              <p>
                {scrapping && (
                  <div className={'flex items-center gap-2'}>
                    <Spinner size={'sm'} color={'default'} />
                    <span className={'text-sm font-light italic opacity-40'}>Extraing data from provided source..</span>
                  </div>
                )}
                {!scrapping && finishScrapping && (
                  <div className={'flex items-center  gap-1 text-success-500'}>
                    <Icon icon="lucide:square-check-big" color={'success'} />
                    <span className={'text-sm font-light text-success-500'}>
                      Project description scraped successfully
                    </span>
                  </div>
                )}
              </p>
              <Button
                variant={'solid'}
                color={'secondary'}
                isDisabled={false}
                size={'md'}
                className={'pl-10 pr-10 w-fit'}
                onClick={getDescription}
              >
                Scrape data from URL now
              </Button>
            </div>
          </div>
        </div>
      )}
      {showFullForm && (
        <>
          <div className="flex row gap-4">
            <div className={'flex flex-1 flex-col'}>
              <h2 className={'text-xl pb-2'}>Project name</h2>
              <div className={'flex flex-row justify-between gap-5'}>
                <Input
                  isRequired
                  variant={'bordered'}
                  label="Project title"
                  placeholder={'e.g. Redesign of Running App “Explorun”'}
                  classNames={{
                    label: 'text-secondary',
                  }}
                  value={title}
                  onChange={(e) => setProjectTitle(e.target.value)}
                />
              </div>
            </div>
            <div className={'flex flex-1 flex-col'}>
              <h2 className={'text-xl pb-2'}>Client name</h2>
              <div className={'flex flex-row justify-between gap-5 h-full'}>
                <CreatableSelect
                  options={clientsFullList.map((item) => ({
                    value: item.uuid,
                    label: item.name,
                  }))}
                  placeholder={'Select or type new'}
                  value={selectedClient}
                  onChange={(value) => {
                    setClientName(value?.label ?? '')
                    setSelectedClient(value)
                  }}
                  onCreateOption={(inputValue: string) => {
                    setClientName(inputValue)
                    setSelectedClient({ label: inputValue, value: null })
                  }}
                  formatCreateLabel={(inputValue: string) => `Create new client: ${inputValue}`}
                  menuPosition="fixed"
                  menuPortalTarget={document.body}
                  styles={{
                    container: (baseStyles, state) => ({
                      ...baseStyles,
                      minWidth: '100%',
                      borderColor: '#e5e7eb',
                      borderWidth: 2,
                      borderRadius: 8,
                      padding: 4,
                      height: '100%',
                      boxShadow: 'none',
                    }),
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: 'none',
                      height: '100%',
                      width: '100%',
                      outline: 'none',
                      boxShadow: 'none',
                    }),
                    menuPortal: (defaultStyles) => ({
                      ...defaultStyles,
                      zIndex: 99999,
                    }),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <h2 className={'text-xl pb-2'}>Project description & special requirements.</h2>
            <div className={'flex flex-col justify-between gap-2'}>
              <Textarea
                minRows={4}
                maxRows={6}
                variant="bordered"
                labelPlacement="outside"
                placeholder="What are the main goals or challenges you want to address with this project?"
                className="w-full"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onBlur={(e) => {
                  loadScope?.(e.target.value)
                }}
              />
              <Progress color={variant} aria-label="Loading..." value={progress} className="w-full" />
              {progress > 0 && (
                <span className={`text-sm text-${variant}`}>
                  {progress < 50 ? 'Weak prompt!' : progress < 90 ? 'Keep going!' : 'Good prompt!'}
                </span>
              )}
            </div>
          </div>
        </>
      )}
      <div className="flex justify-between gap-5">
        <div>
          {/*<Button variant={'light'} className={'w-full'} onClick={() => setShowFullForm((prev) => !prev)}>
            {showFullForm ? 'Scrape project details from URL' : 'Enter project details manually'}
          </Button>*/}
        </div>
        <div className={'flex flex-row gap-2 '}>
          <Button color="secondary" variant="bordered" onPress={() => props.onClose()} className={'pl-10 pr-10'}>
            Cancel
          </Button>
          <Button
            color={'secondary'}
            onPress={() => {
              props.submitData({
                title,
                clientName,
                description,
              })
              props.nextStep()
            }}
            isDisabled={scrapping || title.length === 0 || clientName.length === 0 || description.length === 0}
            className={'pl-10 pr-10'}
            style={{
              backgroundColor: 'linear-gradient(0deg, #3D969E 12.9%, #65D0CA 85.52%)',
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}

function Step2(props) {
  const { data, scopes, scopesLoading, projectUUID, clientUUID } = props
  const [scopeOfProject, setScopeOfProject] = useState<string[]>(data?.scope ?? [])
  const [paymentTerms, setPaymentTerms] = useState<string>(data?.paymentTerms ?? 'FIXED')
  const { activeWorkspace, loadMyClient } = useWorkspaceContextData()
  const { io } = useSocketContextData()
  const navigate = useNavigate()
  const { getUserSubscription, currentLimitations } = useSubscriptionContext()
  const [documentLength, setDocumentLength] = useState<string>(data?.documentLength ?? 'MEDIUM')
  const [documentType, setDocumentType] = useState<string>(data?.documentType ?? 'PROPOSAL')

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col">
        <h2 className={'text-2xl'}>Project overview</h2>
        <p className="pt-0 pb-4 text-base leading-5 text-default-500">
          Provide a brief description of your project, including the main tasks and expected results.
        </p>
        <div className={'gap-2'}>
          {((scopes ?? []).legnth === 0 || scopesLoading) && (
            <div className={'w-full h-full justify-center flex gap-2'}>
              <div className={'flex flex-row gap-2 h-fit '}>
                <Spinner size={'sm'} />
                <span className={'font-light italic opacity-40 text-default-500 text-sm'}>
                  Loading scope of work...
                </span>
              </div>
            </div>
          )}
          {(scopes || []).map((scope: string) => (
            <Button
              key={scope}
              onClick={() => {
                setScopeOfProject((p) => {
                  const prev = [...p]
                  const indexOfPrev = prev.indexOf(scope)
                  if (indexOfPrev > -1) {
                    prev.splice(indexOfPrev, 1)
                    return prev
                  } else {
                    prev.push(scope)
                    return prev
                  }
                })
              }}
              className={
                scopeOfProject.indexOf(scope) > -1 ? 'p-2 m-1 text-white font-medium' : 'p-2 m-1 text-black font-medium'
              }
              variant={scopeOfProject.indexOf(scope) > -1 ? 'solid' : 'bordered'}
              color={'secondary'}
            >
              {scope}
            </Button>
          ))}
        </div>
      </div>
      <div className="flex flex-col">
        <h2 className={'text-2xl pb-5'}>Type of document</h2>
        <div className={'flex flex-row justify-evenly gap-5'}>
          <div
            className={`flex flex-col flex-1 cursor-pointer justify-evenly border-1 rounded-xl text-center p-5 ${
              documentType === 'PROPOSAL' ? 'bg-teal-100' : ''
            }`}
            onClick={() => setDocumentType('PROPOSAL')}
          >
            <div>
              <h3 className={'text-2xl font-bold'}>Proposal</h3>
              <span className={'text-default-500 font-light text-sm'}>Proposal & Offer</span>
            </div>
            <p className={'pt-5 pb-5 font-small'}>
              A formal offer to solve a problem or deliver a project, detailing the approach, timeline, and estimated
              cost.
            </p>
            <Button
              variant={documentType === 'PROPOSAL' ? 'solid' : 'bordered'}
              color={'secondary'}
              isDisabled={false}
              size={'md'}
              className={'pl-10 pr-10'}
              onClick={() => setDocumentType('PROPOSAL')}
            >
              {documentType === 'PROPOSAL' ? 'Selected' : 'Select'}
            </Button>
          </div>
          <div
            className={`flex flex-col flex-1 cursor-pointer justify-evenly border-1 rounded-xl text-center p-5 ${
              documentType === 'QUOTATION' ? 'bg-teal-100' : ''
            }`}
            onClick={() => setDocumentType('QUOTATION')}
          >
            <h3 className={'text-2xl font-bold'}>Quotation</h3>
            <span className={'text-default-500 font-light text-sm'}>quotation sub-title</span>
            <p className={'pt-5 pb-5 font-small'}>
              Describes the needs and expectations of the user, outlining functional and technical requirements for a
              system or product.
            </p>
            <Button
              variant={documentType === 'QUOTATION' ? 'solid' : 'bordered'}
              color={'secondary'}
              isDisabled={false}
              size={'md'}
              className={'pl-10 pr-10'}
              onClick={() => setDocumentType('QUOTATION')}
            >
              {documentType === 'QUOTATION' ? 'Selected' : 'Select'}
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <h2 className={'text-xl pb-2'}>Length & formality of document</h2>
        <div className={'p-1 rounded-xl w-fit bg-success-500'}>
          <Button
            size={'sm'}
            className={documentLength === 'SHORT' ? activeButtonClass : inActiveButtonClass}
            onClick={() => setDocumentLength('SHORT')}
          >
            Short & informal
          </Button>
          <Button
            size={'sm'}
            className={documentLength === 'MEDIUM' ? activeButtonClass : inActiveButtonClass}
            onClick={() => setDocumentLength('MEDIUM')}
          >
            Medium & semi-formal
          </Button>
          <Button
            size={'sm'}
            className={documentLength === 'LONG' ? activeButtonClass : inActiveButtonClass}
            onClick={() => setDocumentLength('LONG')}
          >
            Long & formal
          </Button>
        </div>
      </div>
      <div className="flex flex-col">
        <h2 className={'text-2xl pb-5 flex items-center'}>
          Payment Terms{' '}
          <Chip className="ml-2" variant="solid" color="secondary">
            Optional
          </Chip>
        </h2>
        <div className={'flex flex-row justify-between gap-10'}>
          <RadioGroup
            orientation={'horizontal'}
            value={paymentTerms}
            onChange={(e) => {
              setPaymentTerms(e.target.value)
              props.submitData({
                paymentTerms: e.target.value,
              })
            }}
          >
            <CustomRadio value="FIXED">Fixed Price</CustomRadio>
            <CustomRadio value="HOURLY">Hourly</CustomRadio>
          </RadioGroup>
        </div>
      </div>
      <div className="flex justify-end gap-5">
        <Button color="secondary" variant="bordered" onPress={() => props.previousStep()} className={'pl-10 pr-10'}>
          Previous
        </Button>
        <Button
          color={'secondary'}
          onPress={async () => {
            const formData = {
              ...props.data,
              scope: scopeOfProject.join('\n'),
              paymentTerms,
            }
            props.setLoading?.(true)
            try {
              const res = await io.emitWithAck('createUrs', {
                workspaceUUID: activeWorkspace,
                documentLength,
                documentType,
                projectUUID,
                token: localStorage.getItem('accessToken'),
                ...formData,
              })
              // TODO reload client
              loadMyClient?.(activeWorkspace)
              if (res.error) {
                props.showSubscriptionError()
              } else {
                getUserSubscription?.()
                props.onClose?.()
                navigate(`/client/${clientUUID}/document/${res.uuid}`)

                // Mixpanel tracking for doc wizard step
                Mixpanel.track('Document wizard step completed', {
                  step_number: 3,
                  step_name: 'Budget & terms',
                })

                Mixpanel.track('Document created', {
                  document_id: res.uuid,
                })
              }
            } catch (error) {
              console.error('Document creation failed', error)
              Mixpanel.track('Document creation error', {
                error_message: error,
              })
            } finally {
              props.setLoading?.(false)
            }
          }}
          isDisabled={false}
          className={'pl-10 pr-10'}
          style={{
            backgroundColor: 'linear-gradient(0deg, #3D969E 12.9%, #65D0CA 85.52%)',
          }}
        >
          Generate
        </Button>
      </div>
    </div>
  )
}

export default function CreateUrdWindow(props: any) {
  const { isOpen, onClose, projectUUID } = props
  const [activeStep, setActiveStep] = useState(1)
  const [stepWizard, setStepWizard] = useState(null)
  const [formData, setFormData] = useState<any>({ projectUUID })
  const assignStepWizard = (instance) => {
    setStepWizard(instance)
  }
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      projectUUID,
    }))
  }, [projectUUID])
  const handleStepChange = (e) => {
    setActiveStep(e.activeStep)
  }
  const { clientUUID } = useParams()
  const customStepWizardTransitions = {
    enterRight: 'enter-right', // Class for entering from the right
    enterLeft: 'enter-left', // Class for entering from the left
    exitRight: 'exit-right', // Class for exiting to the right
    exitLeft: 'exit-left', // Class for exiting to the left
    intro: '', // Leave empty if no intro animation is needed
  }
  const { io } = useSocketContextData()
  const [scopes, setScopes] = useState<string[]>([])
  const [scopesLoading, setScopesLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  // @ts-ignore
  return (
    <>
      <Modal
        className={'no-scrollbar'}
        backdrop="blur"
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="4xl"
        isDismissable={false}
      >
        <div
          onClick={(e) => {
            if (e.target.getAttribute('data-slot') === 'wrapper') {
              onClose()
            }
          }}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalBody className={'scrollbar-hide px-2 md:px-20 pb-10'}>
                  <ScrollShadow hideScrollBar size={100} id={'document-wrap-container'}>
                    <Stepper
                      activeStep={activeStep}
                      //@ts-ignore
                      styleConfig={{
                        activeBgColor: '#71717A',
                        completedBgColor: '#65d0ca',
                        inactiveBgColor: '#71717A',
                      }}
                      //@ts-ignore
                      connectorStyleConfig={{
                        disabledColor: '#71717A',
                        activeColor: '#65d0ca',
                        completedColor: '#65d0ca',
                      }}
                    >
                      <Step label="Project info" />
                      <Step label="Project scope" />
                    </Stepper>
                    <StepWizard isLazyMount={true} instance={assignStepWizard} onStepChange={handleStepChange}>
                      <Step1
                        title={'step 1'}
                        onClose={() => {
                          setFormData({})
                          onClose?.()
                        }}
                        data={formData}
                        submitData={(data) => {
                          console.log(data)
                          setFormData((prevData) => ({
                            ...prevData,
                            ...data,
                          }))
                        }}
                        loadScope={async (description: string) => {
                          if (description) {
                            setScopesLoading(true)
                            const scopes = await io.emitWithAck('getScopes', { description })
                            setScopes(scopes.split('\n'))
                            setScopesLoading(false)
                          }
                        }}
                      />
                      <Step2
                        title={'step 2'}
                        onClose={onClose}
                        data={formData}
                        setLoading={setLoading}
                        submitData={(data) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            ...data,
                          }))
                        }}
                        scopes={scopes}
                        scopesLoading={scopesLoading}
                        clientUUID={clientUUID}
                        projectUUID={projectUUID}
                        showSubscriptionError={() => {
                          onClose()
                          props.showSubscriptionError?.()
                        }}
                      />
                    </StepWizard>
                  </ScrollShadow>
                </ModalBody>
              </>
            )}
          </ModalContent>
        </div>
      </Modal>
      {loading && isOpen && (
        <div className={'absolute w-full h-full top-0 left-0'} style={{ zIndex: 999 }}>
          <div className={'bg-black/75 backdrop-blur-sm w-full h-full flex flex-col justify-center items-center gap-2'}>
            <div className={'flex flex-row gap-2 justify-center items-center'}>
              <Spinner size={'lg'} color="secondary" />
              <span className="text-white text-xl">Processing information…</span>
            </div>
            <span className={'text-white text-sm opacity-60 text-center'}>
              It can take up to 30 seconds.
              <br />
              Please wait and keep this browser tab open.
            </span>
          </div>
        </div>
      )}
    </>
  )
}
