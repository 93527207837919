import React, { createContext, useContext, useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import api from 'src/lib/api'

type Props = {
  children: React.ReactNode
}

type WorksapceContextType = {
  activeWorkspace: string
  setActiveWorkspace?: (value: string) => void
  clientLoading: boolean
  clientsList: any[]
  clientsFullList: any[]
  addClient?: (value: any) => void
  editClient?: (value: any) => void
  removeClient?: (value: any) => void
  loadMyClient?: (workspaceUUID: string) => Promise<void>
  checkClient?: (clientUUID: string, documentUUID: string, phase: string) => void
}

export const WorkspaceContext = createContext<WorksapceContextType>({
  activeWorkspace: '',
  clientLoading: true,
  clientsList: [],
  clientsFullList: [],
})

export const WorkspaceProvider = ({ children }: Props) => {
  const [activeWorkspace, setActiveWorkspace] = useState<string>(localStorage.getItem('activeWorkspace') || '')
  const [clientLoading, setClientLoading] = useState<boolean>(false)
  const [clientsList, setClientsList] = useState<any[]>([])
  const [clientsFullList, setClientsFullList] = useState<any[]>([])
  const loadMyClient = async (workspaceUUID: string) => {
    setClientLoading(true)
    const res = await api.get(`/clients/${workspaceUUID}/list`)
    setClientsList(res.data?.filteredClients ?? [])
    setClientsFullList(res.data?.clients ?? [])
    setClientLoading(false)
  }
  useEffect(() => {
    localStorage.setItem('activeWorkspace', activeWorkspace)
    if (activeWorkspace) {
      setClientsList([])
      setClientsFullList([])
      loadMyClient(activeWorkspace)
    }
  }, [activeWorkspace])
  const editClient = (client: any) => {
    const clientIndex = clientsList.findIndex((item) => item.uuid === client.uuid)
    if (clientIndex >= 0) {
      const copyData = [...clientsList]
      copyData[clientIndex] = client
      copyData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      setClientsList(copyData)
    }

    const clientFullListIndex = clientsFullList.findIndex((item) => item.uuid === client.uuid)
    if (clientFullListIndex >= 0) {
      const copyDataFull = [...clientsFullList]
      copyDataFull[clientFullListIndex] = client
      setClientsFullList(copyDataFull)
    }
  }
  const addClient = (client: any) => {
    setClientsFullList([client, ...clientsFullList])
  }
  const checkClient = (clientUUID: string, documentUUID: string, phase: string, newDoc?: any) => {
    if (phase === 'Accepted') {
      // check if this client should appear ont he side bar
      const isAlreadyThere = clientsList.findIndex((client: any) => client.uuid === clientUUID)
      // client doesn't already exist
      if (isAlreadyThere === -1) {
        const client = clientsFullList.filter((client: any) => client.uuid === clientUUID).pop()
        if (client) {
          const doc = client.URS?.filter((item) => item.uuid === documentUUID).pop()
          if (doc) {
            doc.phase = phase
          }
          const newEntry = {
            ...client,
            URS: [...(client.URS ?? []), doc],
          }
          setClientsList((prev) => {
            const newArray = [newEntry, ...prev]
            newArray.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
            return newArray
          })
        }
      }
    } else {
      // we should remove client if no have any more document.
      const isAlreadyThere = clientsList.findIndex((client: any) => client.uuid === clientUUID)
      if (isAlreadyThere > -1) {
        // check total accepted docuemnt
        const client = clientsList[isAlreadyThere]
        const totalDocument =
          client.URS?.filter((item) => item.uuid !== documentUUID && item.phase === 'Accepted').length ?? 0
        if (totalDocument === 0) {
          const clientCopy = [...clientsList]
          clientCopy.splice(isAlreadyThere, 1)
          clientCopy.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
          setClientsList(clientCopy)
        } else {
          // update document status
          const newClient = {
            ...client,
            URS: client.URS?.map((item) => {
              if (item.uuid !== documentUUID) return item
              return {
                ...item,
                phase,
              }
            }),
          }
          const clientCopy = [...clientsList]
          clientCopy[isAlreadyThere] = newClient
          clientCopy.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
          setClientsList(clientCopy)
        }
      }
    }
  }
  const removeClient = (client: any) => {
    const clientIndex = clientsList.findIndex((item) => item.uuid === client.uuid)
    if (clientIndex >= 0) {
      const copyData = [...clientsList]
      copyData.splice(clientIndex, 1)
      copyData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      setClientsList(copyData)
    }
    const clientFullListIndex = clientsFullList.findIndex((item) => item.uuid === client.uuid)
    if (clientFullListIndex >= 0) {
      const copyFullData = [...clientsFullList]
      copyFullData.splice(clientFullListIndex, 1)
      setClientsFullList(copyFullData)
    }
  }
  return (
    <WorkspaceContext.Provider
      value={{
        activeWorkspace,
        setActiveWorkspace,
        clientsList,
        clientLoading,
        addClient,
        editClient,
        removeClient,
        loadMyClient,
        checkClient,
        clientsFullList,
      }}
    >
      <div>
        <Toaster />
      </div>
      {children}
    </WorkspaceContext.Provider>
  )
}
export const useWorkspaceContextData = () => useContext(WorkspaceContext)
